<template>
  <div class="input-group" :class="`input-group-${variant} ${getStatus(error, success)}`">
    <v-md-editor
        mode="edit"
        height="100px"
        left-toolbar="undo redo clear"
        right-toolbar="fullscreen"
        :id="id"
        class="form-control editor"
        :name="name"
        v-model="innerValue"
        :placeholder="placeholder"
        :disabled="disabled || isMaxReached"
        @keydown.enter.prevent="handleSubmit"
    ></v-md-editor>
    <div v-if="maxChars" class="char-counter">
      {{ innerValue.length }} / {{ maxChars }}
    </div>
  </div>
</template>

<script>
import setMaterialInput from "@/assets/js/material-input.js";

export default {
  name: "MaterialTextarea",
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    variant: {
      type: String,
      default: "outline",
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Your text here...",
    },
    isRequired: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    maxChars: {
      type: Number,
      default: null,
    },
  },
  emits: ['update:modelValue', 'submit'],
  data() {
    return {
      innerValue: this.modelValue,
    };
  },
  methods: {
    resetText() {
      this.text = ""; // Reset the text
      this.$emit('input', this.text); // Emit the reset value to ensure v-model in parent is updated
    },
    getStatus(error, success) {
      if (success) return "is-valid";
      if (error) return "is-invalid";
      return null;
    },
    handleSubmit(event) {
      if (!event.shiftKey) {
        this.$emit('submit', this.innerValue);
        this.innerValue = '';
        event.preventDefault();
      }
    },
  },
  computed: {
    isMaxReached() {
      return this.maxChars && this.innerValue.length >= this.maxChars;
    }
  },
  watch: {
    modelValue(newValue) {
      this.innerValue = newValue;
    },
    innerValue(newValue) {
      if (this.maxChars && newValue.length > this.maxChars) {
        this.innerValue = newValue.substring(0, this.maxChars);
      }
      this.$emit('update:modelValue', this.innerValue);
    }
  },
  mounted() {
    setMaterialInput();
  },
};
</script>

<style scoped>
.input-group .form-control {
  border-color: transparent !important;
  font-size: 17px;
}
.dark-version .v-md-editor {
  background: #0a0a0a;
  color: #fff;
}
.dark-version .v-md-textarea-editor textarea {
  background: #0a0a0a;
  color: #fff;
}
</style>