<template>
  <div class="container mt-5">
    <!-- Welcoming Lottie Animation -->
    <div class="animation-container">
      <Vue3Lottie :animationData="welcomeAnimation" :height="300" :width="300" />

    </div>
    <div v-if="experts.length === 0" class="container p-0 text-center">
      <div class="row d-flex justify-content-center align-items-center mb-3 alert alert-info p-0">
        <div class="col-12 col-md-6">
          <div class="alert-icon">
            <i class="fas fa-exclamation-triangle"></i>
          </div>
          <img
                class="w-75 me-3 mb-0"
                src="@/assets/img/illustrations/boy-dynamic-gradient.png"
                alt="logo"
          />
        </div>
        <div class="col-12 col-md-6">
          <h1 style="color: white">Welcome to the Hub!</h1>

          <h2 style="color: white">Start with creating you first expert!</h2>
          <p>
            Step into a world where Virtual Experts—digital personas crafted with cutting-edge AI technology—stand ready to engage, guide, and enlighten. These experts simulate real-life interactions, leveraging artificial intelligence and machine learning to provide tailored advice and solutions, enhancing your experience with precision and innovation.
          </p>

        </div>
        <div class="d-flex justify-content-around p-0">
          <a href="https://b-bot-hub.notion.site/What-is-a-Virtual-Expert-Persona-Actor-c11b27e4b8974f9dacfde9ea0abca3ca?pvs=74" class="btn btn-primary w-100 m-0">
            More Information
          </a>
          <button class="btn btn-primary w-100 m-0" @click="redirectToExperts">
            Go to Experts
          </button>
        </div>
      </div>

    </div>

    <!-- Update Announcements -->
    <div id="update-announcements" class="update-announcements mt-5">
      <h2 class="text-center">🚀 What's New on B-Bot Hub! 🚀</h2>
      <div class="card">
        <div class="card-body">
          <p class="card-text">
            ✨ We're thrilled to announce the <strong>Alpha Release</strong> of the B-bot Hub Training Platform, now live for the public! This isn't just any launch; it's the gateway to revolutionizing how we interact with AI and virtual expertise. Dive into a world where creating, training, and unleashing the potential of Virtual Experts is at your fingertips. Here’s a sneak peek at what you can expect:
          </p>
          <p class="card-text">
            🤖 <strong>Chat & Test Virtual Experts</strong>: Get ready to converse with the future! Test the Virtual Experts crafted by innovative minds like yours. Each interaction is a step into the endless possibilities of AI.
          </p>
          <p class="card-text">
            🎨 <strong>Create and Edit Experts</strong>: Directly shape the capabilities of your Virtual Experts with our intuitive editing and creation tools. Tailor your experts to meet the specific needs and nuances of your projects or objectives.
          </p>
          <p class="card-text">
            🔧 <strong>Training Feature</strong>: Say goodbye to complex training processes. Our simplified training feature demystifies AI, making it accessible and manageable for everyone. It’s time to train smarter, not harder.
          </p>
          <p class="card-text">
            🌟 And this is just the beginning! We're on a mission to democratize AI, making it a tool not just for the tech-savvy but for everyone. Join us on this journey to shape the future of AI and virtual expertise. Let’s make history together!
          </p>
          <p class="card-text">
            Stay tuned for more updates and features as we continuously improve and expand the B-bot Hub Training Platform. Your feedback is invaluable in this journey, so dive in, explore, and let us know what you think!
          </p>
          <p class="card-text">
            👉 Start exploring today and be part of the AI revolution! Let's create, innovate, and inspire together! 🌈💡
          </p>
          <p class="card-text">
            If you need help please visit our Wiki:
          </p>
          <a href="https://b-bot-hub.notion.site/2121943415984a539a529c662cb499fd?v=f031821067194fd986b1c1da0021ad0a" class="btn btn-primary w-100">Wiki</a>
          <a href="https://bbothub.featurebase.app" class="btn btn-primary w-100">Upvote for you Favorite Feature</a>
        </div>
      </div>

      <h2 class="mt-3 text-center">🌟 Upcoming Features 🌟</h2>
      <div class="card">
        <div class="card-body">
          <p class="card-text">Excitement is building at B-bot Hub as we gear up to introduce some game-changing new features! Here's a glimpse into the future that awaits:</p>
          <ul>
            <li>🖥️ <strong>Interactive Dashboard:</strong> Prepare to navigate the B-bot universe like never before with our upcoming dashboard. It's not just functional; it's a command center that brings the power of AI to your fingertips. Stay tuned for a dashboard that promises to enhance your experience, streamline your projects, and provide insightful analytics.</li>
            <li>📚 <strong>More Training Cards Examples:</strong> Get ready to dive deeper into the world of AI with an expanded library of Training Cards Examples. These practical guides and templates are designed to spark creativity, inspire innovation, and simplify the training process for your Virtual Experts. Whether you're a seasoned pro or just starting out, these examples will empower you to achieve more.</li>
            <li>🔮 <strong>A Secret Surprise:</strong> We've got something extraordinary up our sleeves, and we can hardly wait to share it with you! While we can't spill the beans just yet, rest assured, this secret is going to take your B-bot experience to new heights. Keep an eye out for the big reveal – it's something you won't want to miss!</li>
          </ul>
          <p class="card-text">These are just a few of the thrilling developments we have in store. Our team is working tirelessly to bring these features to life, driven by our commitment to innovation and excellence. The future is bright at B-bot Hub, and we're so grateful to have you with us on this journey.</p>
          <p class="card-text">Stay connected, stay curious, and as always, be ready to embrace the future of AI with B-bot Hub! 💡🚀</p>
        </div>
      </div>

    </div>

    <!-- Blog Articles -->
    <div id="blog-articles" class="blog-articles mt-5">
      <h2>Blog Articles</h2>
      <div class="row">
        <div class="col-md-4 mb-4">
          <div class="card" style="width: 100%;">
            <img src="https://b-bot.ch/cdn/shop/files/Design_ohne_Titel_17.png?v=1705508450&width=750" class="card-img-top" alt="">
            <div class="card-body">
              <h5 class="card-title">Navigating the Auth0 Odyssey: Adding Social Logins to B-Bot's Arsenal</h5>
              <p class="card-text">The Epic Quest of Integrating Social Logins into B-Bot
                Hey there, fellow code warriors and digital explorers! Today, I'm pulling back the curtain on our epic quest to integrate Auth0 into the B-Bot Hub—this time, with a twist...</p>
              <a href="https://b-bot.ch/blogs/developer-blog/navigating-the-auth0-odyssey-adding-social-logins-to-b-bots-arsenal" class="btn btn-primary">Read More</a>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-4">
          <div class="card" style="width: 100%;">
            <img src="https://b-bot.ch/cdn/shop/files/Let_s_9.png?v=1705594568&width=750" class="card-img-top" alt="">
            <div class="card-body">
              <h5 class="card-title">Unveiling the News Page: Your Portal to Everything B-Bot</h5>
              <p class="card-text">Welcome to the News Page on the B-Bot Hub
                Hey there, fellow innovators and digital explorers! As part of our ongoing mission to enhance the B-Bot experience, we're excited to take you behind the scenes of our latest endeavor - the creation of the News Page on the B-Bot Hub...</p>
              <a href="https://b-bot.ch/blogs/developer-blog/unveiling-the-news-page-your-portal-to-everything-b-bot" class="btn btn-primary">Read More</a>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-4">
          <div class="card" style="width: 100%;">
            <img src="https://b-bot.ch/cdn/shop/files/Let_s_11.png?v=1706039760&width=750" class="card-img-top" alt="">
            <div class="card-body">
              <h5 class="card-title">Launching the B-Bot Development Blog: A Behind-the-Scenes Journey into the Hub's Creation</h5>
              <p class="card-text">Welcome to the B-Bot Development Blog
                We are thrilled to announce the launch of the B-Bot Development Blog, a new series dedicated to unveiling the intricacies and groundbreaking work behind the development of the B-Bot Hub...</p>
              <a href="https://b-bot.ch/blogs/news/launching-the-b-bot-development-blog-a-behind-the-scenes-journey-into-the-hubs-creation" class="btn btn-primary">Read More</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import welcomeAnimation from '@/assets/img/illustrations/welcomeAnimation.json';
import {mapMutations, mapState} from "vuex";

export default {
  components: {
  },
  data() {
    return {
      welcomeAnimation,
    }
  },
  mounted() {
    this.showEveryDisplay();
  },
  computed: {
    ...mapState(['experts', 'color']),
  },
  methods: {
    ...mapMutations(["showEveryDisplay"]),
    redirectToExperts() {
      // Redirect to the /experts route
      this.$router.push('/experts');
    },
  },
};
</script>

<style>
/* Styling for your components */
.animation-container {
  display: flex;
  justify-content: center;
}
.update-announcements, .blog-articles {
  text-align: left;
}
.alert-icon {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 1rem;
}
.alert{
  color: white !important;
}
</style>
