<template>
  <div class="messages-container">

    <chat-message
        v-for="message in messagesWithProfilePictures"
        :key="message.id"
        :id="message.id"
        :sender="message.sender"
        :text="message.text"
        :thoughts="message.thoughts"
        :timestamp="message.timestamp"
        :profilePictureUrl="message.profilePictureUrl"
        :isSelected="selectedMessages.includes(message.id)"
        :is-selection-mode="isSelectionModeEnabled"
        @start-qa-marking="handleStartQAMarking"
        @select-message="handleSelectMessage"

    />
    <div class="selection-mode-controls">
      <button class="btn btn-primary w-50" v-if="isSelectionModeEnabled" @click="toggleSelectionMode(false)">Cancel Selection</button>
      <button class="btn btn-success w-50"  v-if="isSelectionModeEnabled" @click="saveSelectedMessages">Save</button>
    </div>
  </div>
</template>

<script>
import ChatMessage from './ChatMessage.vue';
import {mapState} from "vuex";

export default {
  name: 'Messages',
  components: {
    ChatMessage,
  },
  data() {
    return {
      selectedMessages: [],
      isSelectionModeEnabled: false,
    };
  },
  computed: {
    ...mapState(['selectedExpert', 'user']),
    messagesWithProfilePictures() {
      return this.messages.map((message, index) => {
        let profilePictureUrl = 'https://t4.ftcdn.net/jpg/04/30/11/17/360_F_430111702_DcBX4q0VE9CZZzyMG42FzoXHdHwM7SfA.jpg'; // Default image
        if (message.sender === 'user' && this.user && this.user.profile) {
          profilePictureUrl = this.user.profile;
        } else if (message.sender !== 'user' && this.selectedExpert && this.selectedExpert.attributes && this.selectedExpert.attributes.profile_picture && this.selectedExpert.attributes.profile_picture.data && this.selectedExpert.attributes.profile_picture.data.attributes && this.selectedExpert.attributes.profile_picture.data.attributes.formats && this.selectedExpert.attributes.profile_picture.data.attributes.formats.thumbnail && this.selectedExpert.attributes.profile_picture.data.attributes.formats.thumbnail.url) {
          profilePictureUrl = this.selectedExpert.attributes.profile_picture.data.attributes.formats.thumbnail.url;
        }
        const id = `message-${index}`;

        return { ...message, profilePictureUrl, id };
      });
    }
  },
  props: {
    messages: Array, // Accept messages as a prop from ChatWindow
  },
  methods: {
    toggleSelectionMode(enable) {
      this.isSelectionModeEnabled = enable;
      if (!enable) {
        this.selectedMessages = []; // Clear selection when exiting mode
      }
    },
    handleMessageClick(message) {
      if (this.isSelectionModeEnabled) {
        this.handleSelectMessage(message);
      }
    },
    handleSelectMessage(message) {
      console.log("Selected Message:", message); // Verify the message object
      const index = this.selectedMessages.findIndex(msg => msg.id === message.id);

      if (index > -1) {
        this.selectedMessages.splice(index, 1); // Remove message if already selected
      } else {
        this.selectedMessages.push(message); // Add message if not already selected
      }

      // Sort the selectedMessages by their id
      this.selectedMessages.sort((a, b) => {
        // Assuming the id format is "message-N", where N is a number
        const idA = parseInt(a.id.split('-')[1], 10);
        const idB = parseInt(b.id.split('-')[1], 10);
        return idA - idB;
      });

      console.log("Selected Messages:", this.selectedMessages); // Verify the updated array
    },
    handleStartQAMarking() {
      this.selectedMessages.splice(0, this.selectedMessages.length);
      if (!this.isSelectionModeEnabled) {
        this.toggleSelectionMode(true); // Automatically enter selection mode if not already in it
      }
    },
    saveSelectedMessages() {
      console.log("Selected Messages:", this.selectedMessages); // Verify the selected messages
      // Sort selected messages by their original order
      const sortedSelectedMessages = this.selectedMessages.sort((a, b) => a.id - b.id);

      // Map over the sorted messages to structure them correctly
      const structuredMessages = sortedSelectedMessages.map(message => {
        let structuredMessage = {
          id: message.id,
          content: message.text,
          role: message.sender,
          timestamp: message.timestamp
        };

        // Check for thoughts
        if (Array.isArray(message.thoughts) && message.thoughts.length > 0) {
          structuredMessage.thoughts = message.thoughts.map(thoughtPair => {
            let [thought, thoughtOutput] = thoughtPair;
            let structuredThought = {
              log: thought.log,
              tool: thought.tool,
              type: thought.type,
              tool_input: thought.tool_input,
              tool_call_id: thought.tool_call_id,
              tool_output: thoughtOutput
            };

            // If message log exists, map over it
            if (Array.isArray(thought.message_log) && thought.message_log.length > 0) {
              structuredThought.message_log = thought.message_log.map(log => ({
                type: log.type,
                content: log.content,
                example: log.example,
                additional_kwargs: log.additional_kwargs
              }));
            }

            return structuredThought;
          });
        }

        return structuredMessage;
      });

      console.log("structuredMessages", JSON.stringify(structuredMessages, null, 2));

      // Dispatch Vuex action 'saveQAPairs' with structuredMessages
      this.$store.dispatch('saveQAPairs', { qaPairs: structuredMessages })
          .then(() => {
            console.log("QA pairs successfully saved.");
            // Reset or handle UI changes post save
            this.toggleSelectionMode(false);
          })
          .catch(error => {
            console.error("Error saving QA pairs:", error);
          });
    }
  },
};
</script>

<style>
.messages-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

/* Example styling for individual messages */
.chat-message {
  margin-bottom: 10px;
}
.selection-mode-controls {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 50;
}

</style>
