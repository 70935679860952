<template>
  <div class="chat-window p-0 card d-flex flex-column">
    <ConversationsModal v-model="showConversationsModal" />
    <AbilitiesModal  v-model="showAbilitiesModal" />
    <ModelsModal v-model="showModelsModal" />
    <ChatSettingsModal v-model="showChatSettingsModal" />
    <ChatConnectorsModal v-model="showChatConnectorsModal"/>
    <div class="messages overflow-auto" ref="messagesContainer">
      <WelcomeMessage @start-chat="startEmptyChat" v-if="chatMessages.length === 0" />
      <Messages v-else :messages="chatMessages" />
      <div v-if="isLoading">
        <vue3-lottie ref="lottie"
           :animationData="animationData"
           height="60vh"
           width="100%"
        />
      </div>
      <div v-if="isErrorInRequest" class="error-indicator p-5">
        <div class="row d-flex justify-content-center align-items-center mb-3 alert alert-danger p-0">
          <div class="col-12 p-5">
            <div class="alert-icon">
              <i class="fas fa-exclamation-triangle"></i>
            </div>
            <h1 style="color: white">Error</h1>
            <p>Error: There was a problem with the request. Please try again.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="chat-input-container">
      <ChatInput
          @sendMessage="handleSendMessage"
          @toggle-conversations-modal="showConversationsModal = !showConversationsModal"
          @toggle-abilites-modal="showAbilitiesModal = !showAbilitiesModal"
          @toggle-models-modal="showModelsModal = !showModelsModal"
          @toggle-settings-modal="showChatSettingsModal = !showChatSettingsModal"
          @toggle-connectors-modal="showChatConnectorsModal = !showChatConnectorsModal"
          @back-button="handleBackButton"
          @scroll-down="scrollToBottom"
      />
    </div>
  </div>
</template>

<script>
import WelcomeMessage from './WelcomeMessage.vue';
import Messages from './Messages.vue';
import ChatInput from './ChatInput.vue';
import { mapActions, mapState } from 'vuex';
import ConversationsModal from "@/views/components/ConversationsModal.vue";
import AbilitiesModal from "@/views/components/AbilitiesModal.vue";
import ModelsModal from "@/views/components/ModelsModal.vue";
import ChatSettingsModal from "@/views/components/ChatSettingsModal.vue";
import ChatConnectorsModal from "@/views/components/ChatConnectorsModal.vue";
import loadingChat from "@/assets/img/illustrations/loadingChat.json";

export default {
  name: 'ChatWindow',
  components: {
    ChatConnectorsModal,
    ChatSettingsModal,
    ModelsModal,
    AbilitiesModal,
    ConversationsModal,
    Messages,
    ChatInput,
    WelcomeMessage
  },
  data() {
    return {
      animationData: loadingChat,
      unsavedChanges: false,
      showConversationsModal: false,
      showAbilitiesModal: false,
      showModelsModal: false,
      showChatSettingsModal: false,
      showChatConnectorsModal: false,
    };
  },
  computed: {
    ...mapState(['chatMessages', 'isLoading', 'isErrorInRequest']),
  },
  methods: {
    ...mapActions(['saveMessage', 'saveChat', 'wipeChat', 'updateUnsavedChanges']),
    handleSendMessage(message) {
      console.log(message);
      this.updateUnsavedChanges(true);
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
    async startEmptyChat(){
      await this.handleSendMessage("");
      this.chatMessages = [];
    },
    checkBeforeLeave(event) {
      if (this.unsavedChanges) {
        // Most modern browsers ignore custom messages and show a standard dialog for confirmation
        event.preventDefault();
        event.returnValue = ''; // Chrome requires returnValue to be set
      }
    },
    handleBackButton(){
      this.$emit('press-back-button'); // Emitting an event to the parent

    },
    saveAndLeave() {
      this.saveChat(); // Assuming this action properly saves the chat
      this.unsavedChanges = false; // Reset unsaved changes flag
      window.removeEventListener('beforeunload', this.checkBeforeLeave);
    },
    scrollToBottom() {
      const container = this.$refs.messagesContainer;
      container.scrollTop = container.scrollHeight - container.clientHeight;
    },
    retry() {
      this.$store.commit('setIsErrorInRequest', false);
      //this.$store.dispatch('retry');
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.scrollToBottom();
    });
  },
  beforeUnmount() {
    // Consider resetting unsavedChanges state if needed
    this.updateUnsavedChanges(false); // Clean up on component unmount
  }
};
</script>


<style scoped>
.chat-window {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 88vh;
  overflow: hidden;
}

.messages{
  padding-top: 4rem;
  padding-bottom: 6rem;
}

.chat-input-container {
  position: fixed;
  bottom: 5px;
  width: 100%; /* Make it full width on smaller screens */
  box-shadow: 0 -4px 6px rgba(0,0,0,0.1); /* Optional: Adds shadow for depth */
}

/* Media query for screens wider than 1200px */
@media (min-width: 1201px) {
  .chat-input-container {
    position: static;
    transform: none;
    left: auto;
    bottom: auto;
  }
}

.main-content {
  overflow: hidden !important;
}
</style>
