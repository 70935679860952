<template>
  <VueFinalModal v-model="isVisible"
                 class="flex justify-center items-center confirm-modal overflow-scroll"
                 content-class="flex flex-col max-w-xl mx-auto p-4 dark:bg-gray-900 dark:border-gray-700 rounded-lg space-y-2 confirm-modal-content"
                 :hide-overlay="true"
                 overlay-transition="vfm-fade"
                 content-transition="vfm-fade"
  >
    <template #default>
      <div v-if="isLoading" class="loading-overlay">
        <vue3-lottie ref="lottie"
                     :animationData="animationData"
                     height="60vh"
                     width="100%"
        />
      </div>
      <div class="card-header bg-gradient-primary d-flex justify-content-between shadow-primary card-header-top">
          <h5 class="modal-title text-white">{{ isEditMode ? 'Edit Expert' : 'Create Expert' }}</h5>

          <button type="button" class="close-btn btn text-white" @click="closeModal">
            <i class="material-icons-round opacity-10 fs-5">close</i>
          </button>
      </div>
      <div class="modal-scrollable-content p-0">
        <div class="steps-indicator d-flex justify-content-between">
          <button
              v-for="step in 8"
              :key="step"
              class="btn"
              :disabled="currentStep !== step"
              :class="{'btn-success shadow-success': currentStep === step, 'btn-secondary': currentStep !== step}"
              @click="currentStep = step"
              style="flex-grow: 1; margin: 0 2px;"
          >
            <span>{{ step }}</span>
            <i class="material-icons-round opacity-10 fs-5 icon-steps">keyboard_arrow_right</i>
          </button>
        </div>
        <transition name="slide-fade" mode="out-in">
          <div :key="currentStep">
            <!-- Business Card -->
            <div class="p-3" v-if="currentStep === 1">
              <h1 class="text-center">Basic Information</h1>

              <div class="mb-3" v-if="!isEditMode">
                <material-input
                    id="name"
                    type="text"
                    label="Name"
                    name="name"
                    size="lg"
                    v-model="expertData.attributes.name"
                />
                <small class="form-text text-muted w-100">Add a name for your Virtual Expert</small>

              </div>
              <div class="mb-3" v-else>
                <material-input
                    id="name"
                    type="text"
                    name="name"
                    size="lg"
                    :disabled="true"
                    v-model="expertData.attributes.name"
                />
                <small class="form-text text-muted w-100">The name of your Virtual Expert</small>
              </div>

              <div class="mb-3" v-if="!isEditMode">
                <material-input
                    id="name"
                    type="text"
                    label="Profession"
                    name="profession"
                    size="lg"
                    v-model="expertData.attributes.profession"
                />
                <small class="form-text text-muted w-100">Add a profession for your Virtual Expert</small>

              </div>
              <div class="mb-3" v-else>
                <material-input
                    id="name"
                    type="text"
                    name="profession"
                    size="lg"
                    :disabled="true"
                    v-model="expertData.attributes.profession"
                />
                <small class="form-text text-muted w-100">The profession of your Virtual Expert</small>
              </div>

              <div class="mb-3" v-if="!isEditMode">
                <material-textarea
                    v-model="expertData.attributes.description"
                    placeholder="Description"
                    class="form-control"
                    id="description"
                    rows="3"
                    :max-chars="250"
                ></material-textarea>
                <small class="form-text text-muted w-100">Add a description for your Virtual Expert</small>
              </div>
              <div class="mb-3" v-else>
                <material-input
                    id="name"
                    type="text"
                    name="profession"
                    size="lg"
                    :disabled="true"
                    v-model=" expertData.attributes.description"
                />
                <small class="form-text text-muted w-100">The description of your Virtual Expert</small>
              </div>

            </div>
            <div v-if="currentStep === 2">
              <h1 class="text-center">Profile Picture</h1>

              <div class="card shadow-sm mb-4">
                <div class="card-body">
                  <div class="text-center">
                    <div class="position-relative d-inline-block mb-3">
                      <MaterialAvatar
                          :img="expertData.attributes?.profile_picture?.data?.attributes?.url || 'https://t4.ftcdn.net/jpg/04/30/11/17/360_F_430111702_DcBX4q0VE9CZZzyMG42FzoXHdHwM7SfA.jpg'"
                          alt="Profile Picture"
                          size="xl"
                          shadow="sm"
                          :circular="true"
                      />
                      <label for="profilePictureUpload" class="btn btn-sm btn-primary position-absolute bottom-75 start-100 translate-middle-x camera-spaces">
                        <i class="fas fa-camera camera-fontsize"></i>
                      </label>
                    </div>
                    <input type="file" id="profilePictureUpload" @change="handleProfilePictureUpload" class="d-none" accept="image/*" />
                    <p class="text-muted small mt-2">For best results, upload a square image (1:1 aspect ratio)</p>
                  </div>
                </div>
              </div>
            </div>

            <!-- Abilities -->
            <div v-if="currentStep === 3">
              <h1 class="text-center">Abilities</h1>

              <div class="card-body ">
                <!-- Check if there are abilities -->
                <div v-if="expertData.attributes.abilities?.data?.length > 0">
                  <div class="row mb-3">
                    <div class="p-3 col-12 col-md-4 col-lg-4" v-for="(ability, index) in expertData.attributes.abilities.data" :key="index">
                      <div class="card p-3" v-if="!ability.isEditing">
                        <p>{{ ability.attributes.text }}</p>
                        <button @click="ability.isEditing = true" class="btn btn-sm btn-secondary mr-button mb-0 ml-0 mt-3">Edit</button>
                        <button @click="deleteAbility(index)" class="btn btn-sm btn-danger mb-0 mt-3">Delete</button>

                      </div>
                      <div class="card p-3" v-else>
                        <input v-model="ability.attributes.text" class="form-control mb-2" />
                        <button @click="saveAbility(ability, index)" class="btn btn-sm bg-gradient-primary">Save</button>
                      </div>
                    </div>
                  </div>
                  <button @click="generateAbility" class="btn btn-info">Generate new Ability</button>

                </div>
                <!-- Show explanation and generate button if no abilities -->
                <div v-else class="text-center">
                  <p>No abilities have been added yet. Abilities help to describe what your expert can do. Click the button below to generate initial abilities.</p>
                  <button @click="generateAbility" class="btn bg-gradient-info">Generate Initial Ability</button>
                </div>
              </div>
              <div class="container p-0 text-center">
                <div class="row d-flex justify-content-center align-items-center mb-5 alert alert-info p-0">
                  <div class="col-12 col-md-6">
                    <div class="alert-icon">
                      <i class="fas fa-exclamation-triangle"></i>
                    </div>
                    <img
                        class="w-75 me-3 mb-0"
                        src="@/assets/img/illustrations/file-text-dynamic-gradient.png"
                        alt="logo"
                    />
                  </div>
                  <div class="col-12 col-md-6">
                    <h1 style="color: white">Provide Abilities!</h1>

                    <h2 style="color: white">Create Abilities for your Expert</h2>
                    <p>
                      You can steer the Experts behaviours in different situations by providing abilities. Abilities are predefined actions that the expert can take. Click the button below to generate initial abilities.
                    </p>

                  </div>
                  <div class="d-flex justify-content-around p-0">
                    <button class="btn btn-primary w-100 m-0" @click="toAbilitiesDoc()">
                      More Information
                    </button>
                  </div>
                </div>

              </div>
            </div>


            <!-- Templates -->
            <div v-if="currentStep === 4">
              <h1 class="text-center">Templates</h1>

              <div class="card-body">
                <!-- Check if there are templates -->
                <div v-if="expertData.attributes.templates?.data?.length > 0">
                  <div class="row mb-3">
                    <div class="p-3 col-12 col-md-4 col-lg-4" v-for="(template, index) in expertData.attributes.templates.data" :key="index">
                      <div class="card p-3" v-if="!template.isEditing">
                        <div>{{ template.attributes.template_text }}</div>
                        <button @click="template.isEditing = true" class="btn btn-sm bg-gradient-secondary mr-button">Edit</button>
                        <button @click="deleteTemplate(index)" class="btn btn-sm bg-gradient-danger">Delete</button>
                      </div>
                      <div class="card p-3" v-else>
                        <material-textarea v-model="template.attributes.template_text" class="form-control mb-2"  id="textarea-template"/>
                        <button @click="saveTemplate(template, index)" class="btn btn-sm bg-gradient-primary">Save</button>
                      </div>
                    </div>
                  </div>
                  <button @click="generateTemplate" class="btn bg-gradient-info">Add new Template</button>
                </div>
                <!-- Show explanation and generate button if no templates -->
                <div v-else class="text-center">
                  <p>No templates have been added yet. Templates are predefined formats for tasks and communications. Click the button below to generate initial templates.</p>
                  <button @click="generateTemplate" class="btn bg-gradient-info">Generate Initial Template</button>
                </div>
              </div>
              <div class="container p-0 text-center">
                <div class="row d-flex justify-content-center align-items-center mb-5 alert alert-info p-0">
                  <div class="col-12 col-md-6">
                    <div class="alert-icon">
                      <i class="fas fa-exclamation-triangle"></i>
                    </div>
                    <img
                        class="w-75 me-3 mb-0"
                        src="@/assets/img/illustrations/file-text-dynamic-gradient.png"
                        alt="logo"
                    />
                  </div>
                  <div class="col-12 col-md-6">
                    <h1 style="color: white">Provide Templates!</h1>

                    <h2 style="color: white">Create Templates for your users so they know what to ask</h2>
                    <p>
                      Give some examples to your users so they know how to use your expert. soon you will be able to connect an ability to the templates!
                    </p>

                  </div>
                  <div class="d-flex justify-content-around p-0">
                    <button class="btn btn-primary w-100 m-0" @click="toTemplateDoc()">
                      More Information
                    </button>
                  </div>
                </div>

              </div>
            </div>


            <div v-if="currentStep === 5">
              <h1 class="text-center">Model Selection</h1>

              <ExpertChooseModel :edit-mode="true" @to-next="currentStep ++" />
            </div>

            <div v-if="currentStep === 6">
              <h1 class="text-center">App Connections</h1>

              <p>Review Expert App Connections and delete them here</p>
              <ExpertApps @to-next="currentStep ++" :expertApps="expertData.attributes.apps" @delete-app="deleteApp" />
            </div>

            <div v-if="currentStep === 7">
              <h1 class="text-center">Team</h1>

              <ExpertTeam :experts="experts" :expertTeam="expertData.attributes.experts.data" @update:expertTeam="updateExpertTeam" />
            </div>

            <div v-if="currentStep === 8">
              <ExpertCV :expertData="expertData" />

            </div>

          </div>
        </transition>


        <div class="row">
          <div class="col-md-8 mx-auto">
            <!-- Add this section for validation errors -->
            <div v-if="validationErrors.length" class="mt-4">
              <material-alert
                  v-for="(error, index) in validationErrors"
                  :key="index"
                  class="font-weight-light"
                  color="danger"
                  dismissible
                  @dismissed="clearValidationErrors"
              >
                <span class="text-md">{{ error.path }}</span>
                <span class="text-sm">{{ error.message }}</span>
              </material-alert>
            </div>
          </div>
        </div>

        <!-- Navigation Buttons -->
        <div class="navigation-buttons-container">
          <div class="navigation-buttons d-flex justify-content-between">
            <button
                class="btn btn-secondary flex-fill me-2"
                @click="currentStep--"
            >
              Previous
            </button>

            <button v-if="currentStep === 8" type="submit" class="btn flex-fill bg-gradient-success shadow-success" @click="handleSubmit">
              {{ isEditMode ? 'Update' : 'Create' }}
            </button>
            <button
                class="btn btn-secondary flex-fill"
                @click="handleNextStep"
                v-if="currentStep < 8"
            >
              {{ currentStep === 1 && !isEditMode || currentStep === 5 && !isEditMode ? 'Save and Proceed' : 'Next' }}
            </button>
          </div>
        </div>



      </div>
    </template>
  </VueFinalModal>
</template>

<script>
import {VueFinalModal} from "vue-final-modal";
import MaterialAvatar from "@/components/MaterialAvatar.vue";
import MaterialAlert from "@/components/MaterialAlert.vue";
import ExpertCV from "@/views/components/ExpertCV.vue";
import ExpertChooseModel from "@/views/components/ExpertChooseModel.vue";
import {mapActions, mapState} from "vuex";
import loadingChat from "@/assets/img/illustrations/loadingChat.json";
import ExpertApps from "@/views/components/ExpertApps.vue";
import ExpertTeam from "@/views/components/ExpertTeam.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue"; // Import the TrainingInfo component

const { VUE_APP_MAIN_API_URL, VUE_APP_DATACENTER_URL } = process.env;

export default {
  components: {
    MaterialTextarea,
    ExpertChooseModel,
    MaterialAvatar,
    VueFinalModal,
    MaterialAlert,
    ExpertCV,
    ExpertApps,
    ExpertTeam,
    MaterialInput
  },
  props: {
    isEditMode: Boolean,
    initialExpert: Object,
  },
  data() {
    return {
      animationData: loadingChat,
      isVisible: false,
      isLoading: false,
      currentStep: 1,
      expertData: this.initializeExpertData(),
      transitionName: 'slide-fade',
      validationErrors: [],
      snackbar: null,
    };
  },
  computed: {
    ...mapState([
        'experts',
        'selectedModelForAgentCreation',
        'selectedProviderForAgentCreation',
    ]),
  },
  methods: {
    ...mapActions(['fetchExperts', 'updateModel']),

    async updateExpertTeam(newTeam) {
      console.log('Updating expert team:', newTeam);

      // Determine the API URL and HTTP method
      const url = `${VUE_APP_DATACENTER_URL}/api/experts/${this.expertData.id}`;
      const method = 'PUT'; // Use PUT to update the existing expert's team

      const accessToken = this.getCookie('datacenterAccessToken');

      try {
        const response = await fetch(url, {
          method: method,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            data: {
              experts: newTeam, // Assuming 'team' is the correct field to update
            },
          }),
        });

        if (!response.ok) {
          console.log(response);
          throw new Error(`Failed to update expert team: ${response.toString()}`);
        }

        const responseData = await response.json();
        console.log("Expert team updated successfully:", responseData);

        // Update the local expertData with the new team data
        this.expertData.attributes.experts.data = newTeam;

        // Optionally, if you're using Vuex to manage the state, commit the updated team array
        // this.$store.commit('updateExpertTeam', newTeam);

      } catch (error) {
        console.error('Error updating expert team:', error);
      }
    },
    async handleModelSave() {
      // Mapping of model names to provider-specific identifiers
      const modelIdentifierMap = {
        'GPT-4': {
          'OpenAI': '-', // Replace with actual identifier if available
          'Azure': 'gpt-4-0613',
          'TuneAI': '-'
        },
        'GPT-4o-mini': {
          'OpenAI': 'gpt-4o-mini-2024-07-18',
          'Azure': 'gpt-4o-mini-2024-07-18',
          'TuneAI': '-'
        },
        'GPT-3.5-turbo-0125': {
          'OpenAI': 'gpt-3.5-turbo-0125',
          'Azure': 'gpt-35-turbo-0125',
          'TuneAI': '-'
        },
        'GPT-3.5-turbo-0613': {
          'OpenAI': 'gpt-3.5-turbo-0613',
          'Azure': 'gpt-35-turbo-0613',
          'TuneAI': '-'
        },
        'GPT-3.5-turbo-1106': {
          'OpenAI': 'gpt-3.5-turbo-1106',
          'Azure': 'gpt-35-turbo-1106',
          'TuneAI': '-'
        },
        'Llama-3.1-8b-instruct': {
          'OpenAI': '-',
          'Azure': '-',
          'TuneAI': 'meta/llama-3.1-8b-instruct'
        },
        'Llama-3.1-70b-instruct': {
          'OpenAI': '-',
          'Azure': '-',
          'TuneAI': 'meta/llama-3.1-70b-instruct'
        },
        'Llama-3.1-405b-instruct': {
          'OpenAI': '-',
          'Azure': '-',
          'TuneAI': 'meta/llama-3.1-405b-instruct'
        },
        'Mistral-Large2': {
          'OpenAI': '-',
          'Azure': '-',
          'TuneAI': 'rohan/mistral-large-2'
        },
        'Meta-Llama-3-8B-Instruct': {
          'OpenAI': '-',
          'Azure': '-',
          'TuneAI': 'rohan/Meta-Llama-3-8B-Instruct'
        },
        'Meta-Llama-3-70B-Instruct': {
          'OpenAI': '-',
          'Azure': '-',
          'TuneAI': 'rohan/Meta-Llama-3-70B-Instruct'
        },
        'mixtral-8x7b-inst-v0-1-32k': {
          'OpenAI': '-',
          'Azure': '-',
          'TuneAI': 'rohan/mixtral-8x7b-inst-v0-1-32k'
        },
        'tune-wizardlm-2-8x22b': {
          'OpenAI': '-',
          'Azure': '-',
          'TuneAI': 'rohan/tune-wizardlm-2-8x22b'
        },
        'tune-mythomax-l2-13b': {
          'OpenAI': '-',
          'Azure': '-',
          'TuneAI': 'rohan/tune-mythomax-l2-13b'
        },
        'openrouter-goliath-120b-4k': {
          'OpenAI': '-',
          'Azure': '-',
          'TuneAI': 'rohan/openrouter-goliath-120b-4k'
        },
        'gemma-2-27b-it': {
          'OpenAI': '-',
          'Azure': '-',
          'TuneAI': 'rohan/gemma-2-27b-it'
        }
      };

      let modelData;

      // Get the model name and provider
      const modelName = this.selectedModelForAgentCreation.type;
      const provider = this.selectedProviderForAgentCreation;

      // Get the correct identifier for the provider
      const identifier = modelIdentifierMap[modelName][provider];

      // Prepare the model data with the identifier
      modelData = {
        identifier: identifier,
        provider: provider.toLowerCase()
      };

      // Get the model ID to update
      let modelId = this.expertData.attributes.expert_llm_models.data[0].id;

      // Update the model with the new data
      await this.updateModel({modelId, modelData});

      // Move to the next step
      this.currentStep++;
    },
    initializeExpertData() {
      if (this.initialExpert) {
        return JSON.parse(JSON.stringify(this.initialExpert)); // Deep copy if initialExpert exists
      } else {
        // Default structure for a new expert
        return {
          attributes: {
            profile_picture: { data: { attributes: { formats: { small: { url: '' } } } } },
            name: '',
            description: '',
            profession: '',
            functionName: '',
            functionDescription: '',
            systemMessage: '',
            templates: { data: [] },
            abilities: { data: [] },
          },
        };
      }
    },
    async handleProfilePictureUpload(event) {
      this.isLoading = true;
      const file = event.target.files[0];
      if (file) {
        try {
          const formData = new FormData();
          console.log(this.expertData);
          formData.append("files", file); // 'files' is typically the field name expected by backend services for file uploads, but adjust based on your API's expectation
          // If you need to send the expert ID or other data, append them to formData as well
          formData.append("ref", "api::expert.expert"); // Adjust based on your API's requirements
          formData.append("refId", this.expertData.id); // Adjust based on your API's requirements
          formData.append("field", "profile_picture"); // Adjust based on your API's requirements

          const accessToken = this.getCookie('datacenterAccessToken'); // Implement getCookie to extract cookie value
          console.log("Access Token:",accessToken)
          const response = await fetch(VUE_APP_DATACENTER_URL + '/api/upload', { // Adjust URL/path as necessary
            method: 'POST',
            headers: {
              // 'Content-Type': 'application/json', // Note: Do not set content type when using FormData; the browser sets it to 'multipart/form-data' and includes the boundary parameter automatically
              'Authorization': `Bearer ${accessToken}`,
            },
            body: formData,
          });

          if (!response.ok) {
            throw new Error(`Failed to upload image: ${response.statusText}`);
          }

          const responseData = await response.json();
          console.log("Image uploaded successfully:", responseData);
          // Ensure the entire path exists before setting the response data
          if (!this.expertData.attributes.profile_picture) {
            this.expertData.attributes.profile_picture = { data: { attributes: {} } };
          } else if (!this.expertData.attributes.profile_picture.data) {
            this.expertData.attributes.profile_picture.data = { attributes: {} };
          }
          this.expertData.attributes.profile_picture.data.attributes = responseData[0];
          // Update your state or perform actions based on the response
        } catch (error) {
          console.error('Error uploading image:', error);
        }
      }
      this.isLoading = false;
    },
    getCookie(name) {
      let value = `; ${document.cookie}`;
      let parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    },
    handleNextStep() {
      this.currentStep++;
    },
    async callApiFunction(requestBody) {
      try {
        const response = await fetch(VUE_APP_MAIN_API_URL + '/api/v0/function', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data; // Assuming the API returns the desired data
      } catch (error) {
        this.isLoading = false;
        console.error('Failed to call API:', error);
        return null; // Or handle this case as appropriate for your application
      }
    },
    generateTemplate() {
      const newTemplate = {
        id: null, // A unique identifier for the ability, usually provided by the backend.
        attributes: {
          template_name: 'Write a Bussinesplan', // The name or title of the template.
          template_text: 'Write a bussinesplan for my Bussines [Company Name]',
          isEditing: true // Indicates whether this ability is currently being edited.
        }
      };

      // Ensure that this.expertData.attributes.abilities and its data property are initialized
      if (!this.expertData.attributes.templates) {
        this.expertData.attributes.templates = { data: [] };
      }

      if (!Array.isArray(this.expertData.attributes.templates.data)) {
        this.expertData.attributes.templates.data = [];
      }

      this.saveTemplate(newTemplate)
    },
    async generateAbility() {
      const functionGenerateAbility = {
        messages: [
          {"role": "system", "message": "generate an ability of the expert described"},
          {
            "role": "system",
            "message": "Act as the B-Bot Hub Incubator, you only generate information, you never comment anything. Your purpose is to generate the content of B-Bots experts"
          },
          {"role": "user", "message": `generate the Ability content of: ${this.expertData.attributes.description} | these are the abilities already generated: ${this.expertData.attributes.abilities.data.map(ability => ability.attributes.text).join(', ')}`}
        ]
      };
      const genereateAbilityResponse = await this.callApiFunction(functionGenerateAbility)
      let messageContent = genereateAbilityResponse.completion.choices[0].message.content;

      const newAbility = {
        id: null, // A unique identifier for the ability, usually provided by the backend.
        attributes: {
          name: 'Ability', // The name or title of the ability.
          text: messageContent, // A description of what the ability entails.
          isEditing: true // Indicates whether this ability is currently being edited.
        }
      };

      // Ensure that this.expertData.attributes.abilities and its data property are initialized
      if (!this.expertData.attributes.abilities) {
        this.expertData.attributes.abilities = {data: []};
      }

      if (!Array.isArray(this.expertData.attributes.abilities.data)) {
        this.expertData.attributes.abilities.data = [];
      }

      this.saveAbility(newAbility);
    },
    openModal() {
      this.currentStep = 1;
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
    async handleSubmit(with_close = true) {
      console.log("handleSubmit");
      const url = this.expertData.id
          ? `${VUE_APP_DATACENTER_URL}/api/experts/${this.expertData.id}`
          : `${VUE_APP_DATACENTER_URL}/api/experts`;

      const method = this.expertData.id ? 'PUT' : 'POST';

      const accessToken = this.getCookie('datacenterAccessToken');

      try {
        // Prepare the apps data
        let appsData = {};
        if (this.expertData.attributes.apps) {
          if (Array.isArray(this.expertData.attributes.apps.data)) {
            // If apps.data is an array, convert it to an object
            appsData = this.expertData.attributes.apps.data.reduce((acc, app) => {
              if (app.id) {
                acc[app.id] = app;
              }
              return acc;
            }, {});
          } else if (typeof this.expertData.attributes.apps === 'object') {
            // If apps is already an object, use it directly
            appsData = this.expertData.attributes.apps;
          }
        }

        const response = await fetch(url, {
          method: method,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            data: {
              name: this.expertData.attributes.name,
              description: this.expertData.attributes.description,
              profession: this.expertData.attributes.profession,
              function_name: this.expertData.attributes.function_name,
              function_description: this.expertData.attributes.function_description,
              system_message: this.expertData.attributes.system_message,
              apps: appsData,
            },
          }),
        });

        if (!response.ok) {
          throw new Error(`Failed to ${this.expertData.id ? 'update' : 'create'} expert: ${response.statusText}`);
        }

        const responseData = await response.json();

        console.log(responseData);
        setTimeout(() => {
          this.fetchExperts();
          console.log("fetchExperts");
        }, 1000);
        if (with_close){
          this.closeModal();
        }
      } catch (error) {
        console.error('Error submitting expert data:', error);
      }
    },
    async saveTemplate(template, index) {

      const url = template.id
          ? `${VUE_APP_DATACENTER_URL}/api/templates/${template.id}` // PATCH für bestehende Fähigkeit
          : `${VUE_APP_DATACENTER_URL}/api/templates`; // POST für neue Fähigkeit

      const method = template.id ? 'PUT' : 'POST';

      const accessToken = this.getCookie('datacenterAccessToken');
      console.log("ID: ",this.expertData.id)
      try {
        const response = await fetch(url, {
          method: method,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            data: {
              template_name: template.attributes.template_name,
              template_text: template.attributes.template_text,
              experts: this.expertData.id,
            },
          }),
        });

        if (!response.ok) {
          console.log(response)
          throw new Error(`Failed to save ability: ${response.toString()}`);
        }

        const responseData = await response.json();
        console.log("Ability saved successfully:", responseData);

        // Prepare the ability object with potentially new data from the response
        const updatedTemplate = {
          id: responseData.id, // Assuming responseData contains the new or updated ID
          attributes: {
            ...template.attributes,
            isEditing: false
            // Include other relevant data from responseData if necessary
          },
        };

        // Update the local expertData abilities array
        if (index !== undefined && this.expertData.attributes.templates.data[index]) {
          this.expertData.attributes.templates.data[index] = updatedTemplate;
        } else {
          this.expertData.attributes.templates.data.push(updatedTemplate);
        }

        // Optionally, if you're using Vuex to manage the state, commit the updated abilities array
        // this.$store.commit('updateExpertAbilities', this.expertData.attributes.abilities.data);
      } catch (error) {
        console.error('Error saving ability:', error);
      }
    },
    async deleteTemplate(templateIndex) {
      // Optional: Confirm with the user before deletion
      if (!confirm("Are you sure you want to delete this template?")) {
        return;
      }

      const template = this.expertData.attributes.templates.data[templateIndex];
      if (template && template.id) {
        try {
          const accessToken = this.getCookie('datacenterAccessToken');
          const response = await fetch(`${VUE_APP_DATACENTER_URL}/api/templates/${template.id}`, {
            method: 'DELETE',
            headers: {
              'Authorization': `Bearer ${accessToken}`,
            },
          });

          if (!response.ok) {
            throw new Error(`Failed to delete template: ${response.statusText}`);
          }

          console.log("Template deleted successfully");
          // Remove the template from the array
          this.expertData.attributes.templates.data.splice(templateIndex, 1);
          const expertId = this.expertData.id
          const templateId = template.id;
          this.$store.commit('deleteTemplate', { expertId, templateId });

        } catch (error) {
          console.error('Error deleting template:', error);
        }
      } else {
        // For new (unsaved) templates, just remove them from the array
        this.expertData.attributes.templates.data.splice(templateIndex, 1);
      }
    },
    async saveAbility(ability, index) {
      console.log("saveAbility", ability, index);

      const url = ability.id
          ? `${VUE_APP_DATACENTER_URL}/api/abilities/${ability.id}` // PATCH für bestehende Fähigkeit
          : `${VUE_APP_DATACENTER_URL}/api/abilities`; // POST für neue Fähigkeit

      const method = ability.id ? 'PUT' : 'POST';

      const accessToken = this.getCookie('datacenterAccessToken');
      console.log("ID: ",this.expertData.id)
      try {
        const response = await fetch(url, {
          method: method,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            data: {
              name: ability.attributes.name,
              text: ability.attributes.text,
              experts: this.expertData.id,
            },
          }),
        });

        if (!response.ok) {
          console.log(response)
          throw new Error(`Failed to save ability: ${response.toString()}`);
        }

        const responseData = await response.json();
        console.log("Ability saved successfully:", responseData);

        // Prepare the ability object with potentially new data from the response
        const updatedAbility = {
          id: responseData.id, // Assuming responseData contains the new or updated ID
          attributes: {
            ...ability.attributes,
            isEditing: false
            // Include other relevant data from responseData if necessary
          },
        };

        // Update the local expertData abilities array
        if (index !== undefined && this.expertData.attributes.abilities.data[index]) {
          this.expertData.attributes.abilities.data[index] = updatedAbility;
        } else {
          this.expertData.attributes.abilities.data.push(updatedAbility);
        }

        // Optionally, if you're using Vuex to manage the state, commit the updated abilities array
        // this.$store.commit('updateExpertAbilities', this.expertData.attributes.abilities.data);
      } catch (error) {
        console.error('Error saving ability:', error);
      }
    },
    async deleteAbility(abilityIndex) {
      if (!confirm("Are you sure you want to delete this ability?")) {
        return;
      }

      const ability = this.expertData.attributes.abilities.data[abilityIndex];
      if (ability && ability.id) {
        try {
          const accessToken = this.getCookie('datacenterAccessToken');
          const response = await fetch(`${VUE_APP_DATACENTER_URL}/api/abilities/${ability.id}`, {
            method: 'DELETE',
            headers: {
              'Authorization': `Bearer ${accessToken}`,
            },
          });

          if (!response.ok) {
            throw new Error(`Failed to delete ability: ${response.statusText}`);
          }

          console.log("Ability deleted successfully");
          // Remove the template from the array
          this.expertData.attributes.abilities.data.splice(abilityIndex, 1);
          const expertId = this.expertData.id
          const abilityId = ability.id;
          this.$store.commit('deleteAbility', { expertId, abilityId });

        } catch (error) {
          console.error('Error deleting ability:', error);
        }
      } else {
        // For new (unsaved) templates, just remove them from the array
        this.expertData.attributes.abilities.data.splice(abilityIndex, 1);
      }
    },
    closeSnackbar() {
      this.snackbar = null;
    },
    clearValidationErrors() {
      this.validationErrors = [];
    },
    async deleteApp(appKey) {
      console.log('Deleting app:', appKey);

      // Ensure apps exists and is an object before deleting
      if (this.expertData.attributes.apps && typeof this.expertData.attributes.apps === 'object') {
        delete this.expertData.attributes.apps[appKey];
      } else {
        console.warn('Apps structure is not as expected');
      }

      // Save the changes
      await this.handleSubmit(false);

      console.log(`App ${appKey} deleted and changes saved`);
    },
    async toTemplateDoc() {
      // Handle navigation to the template documentation page
      // You can use Vue Router to navigate to the desired page
      console.log('Navigating to template documentation page');
    },
    async toAbilitiesDoc() {
      // Handle navigation to the template documentation page
      // You can use Vue Router to navigate to the desired page
      console.log('Navigating to abilities documentation page');
    },
    async deleteTeamMember(memberId) {
      if (!confirm("Are you sure you want to remove this team member?")) {
        return;
      }

      try {
        const accessToken = this.getCookie('datacenterAccessToken');
        const response = await fetch(`${VUE_APP_DATACENTER_URL}/api/experts/${this.expertData.id}/team-members/${memberId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Failed to delete team member: ${response.statusText}`);
        }

        console.log("Team member removed successfully");
        // Remove the team member from the local data
        this.expertData.attributes.experts = this.expertData.attributes.experts.filter(member => member.id !== memberId);
      } catch (error) {
        console.error('Error deleting team member:', error);
      }
    },
  },
  watch: {
    currentStep(newVal, oldVal) {
      this.transitionName = newVal > oldVal ? 'slide-fade' : 'slide-fade-reverse';
    },
    initialExpert: {
      handler() {
        this.expertData = this.initializeExpertData(); // Reinitialize expertData when initialExpert changes
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>


<style scoped>
.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content-card {
  width: 90%;
  max-width: 600px;
  margin: auto;
  overflow: hidden; /* Prevents content from overflowing the modal */
}

.modal-fullscreen {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.modal-scrollable-content {
  padding: 15px;
  overflow-y: auto; /* Enables scrolling for the content area */
  overflow-x: hidden;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.card-header-top{
  border-radius: 0 0 0.75rem 0.75rem !important;
}

.card-body, .card-footer {
  padding: 1rem;
}

.btn-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
}

.mr-button{
  margin-right: 1rem;
}

.business-card {
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  padding: 20px;
}

.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-picture {
  margin-right: 15px;
  border-radius: 50%;
}

.card-title {
  margin: 0;
  color: #333333;
  font-weight: bold;
}


.navigation-buttons-container {
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.navigation-buttons {
  pointer-events: auto;
  width: 100%;
  background: white;
}

/* Slide-fade transition for entering and leaving */
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.slide-fade-enter, .slide-fade-leave-to /* leave-to for <= Vue 2.1.8 */ {
  transform: translateX(-100%);
  opacity: 0;
}

/* Backward transition */
.slide-fade-reverse-enter-active, .slide-fade-reverse-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.slide-fade-reverse-enter, .slide-fade-reverse-leave-to /* leave-to for Vue 2.1.8 and below */ {
  transform: translateX(100%);
  opacity: 0;
}
.icon-steps{
  position: absolute;
  top: 0.7rem;
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1.7rem;
  margin-right: 1rem;
  color: white;
}

.confirm-modal {
  overflow-y: scroll !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000 !important;
  border-bottom-left-radius: .75rem;
  border-bottom-right-radius: .75rem;
}


.confirm-modal >>> .confirm-modal-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: #fff;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}


.confirm-modal-content h1 {
  font-size: 1.375rem;
}

.dark .confirm-modal-content {
  background: #000;
}
.active .card{
  border: 3px solid green;
}
.card-header{
  padding: .5rem 1rem;
  border-radius: .75rem .75rem .75rem .75rem !important;
}

.steps-indicator{
  margin-bottom: 2rem;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2em;
  z-index: 9999;
}

.dark-version .vfm--overlay {
  background: black;
}

.dark-version .vfm--content {
  background: black;
}
.camera-spaces{
  margin: 0;
  padding: 0 6px;
}
.camera-fontsize{
  font-size: 0.6rem;
}
</style>
