import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'; // Assuming you have Vuex store setup
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import MaterialDashboard from './material-dashboard'; // Assuming this is the correct import for Material Dashboard
import vSelect from "vue-select";
import { authService } from '@/auth/authService'; // Adjust the path as necessary
import { createVfm } from 'vue-final-modal'
import 'vue-final-modal/dist/style.css';
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import VMdEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/preview.css';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
import enUS from '@kangc/v-md-editor/lib/lang/en-US';
import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index';
import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css';
import VNetworkGraph from "v-network-graph"
import "v-network-graph/lib/style.css"



// Create Vue application instance
import Vue3Lottie from 'vue3-lottie'
import { TroisJSVuePlugin } from 'troisjs';

const appInstance = createApp(App);

const vfm = createVfm()

import hljs from 'highlight.js';

VMdPreview.use(githubTheme, {
    Hljs: hljs,
});
VMdEditor.use(githubTheme, {
    Hljs: hljs,
});
VMdEditor.lang.use('en-US', enUS);
VMdEditor.use(createCopyCodePlugin());
VMdPreview.use(createCopyCodePlugin());

// Use plugins
appInstance.use(store);
appInstance.use(router);
appInstance.use(MaterialDashboard);
appInstance.use(vfm);
appInstance.use(Vue3Lottie);
appInstance.use(VMdPreview);
appInstance.use(VMdEditor);
appInstance.use(VNetworkGraph);
appInstance.use(TroisJSVuePlugin);

appInstance.component("v-select", vSelect);
// Configure Auth0 client
authService.initClient().then(() => {
    store.dispatch('fetchExperts').then(() => {
        store.dispatch('getUser').then(() => {
            store.dispatch('fetchAuthor').then(() => { // Fetch and store only the first author
                store.dispatch('fetchModels').then(() => { // Fetch and store only the first author
                    store.dispatch('fetchStatistics').then(() => { // Fetch and store only the first author
                        appInstance.mount("#app");
                    });
                });
            });
        });
    });
});
