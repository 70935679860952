<template>
  <div class="container-fluid px-0">

    <div v-if="currentStep === 'selectExpert'">
      <div class="row mb-4">

        <div class="col-md-12 col-lg-12 position-relative z-index-2">
          <ExpertsList @expert-selected="handleExpertSelected"/>
        </div>
      </div>
    </div>

    <div v-if="currentStep === 'chat'">
      <div class="row mb-4">
        <div class="col-md-12 col-lg-12">
          <div class="flex-column">
            <ChatWindow @press-back-button="backToExpertSelection" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExpertsList from './components/ExpertList.vue'; // Adjust the import path as needed
import ChatWindow from "@/views/components/ChatWindow.vue"; // Adjust the import path as needed
import {mapActions, mapMutations} from "vuex";

export default {
  name: "Chat",
  components: {
    ChatWindow,
    ExpertsList,
  },
  data() {
    return {
      currentStep: 'selectExpert' // Initial step
    };
  },
  methods: {
    ...mapMutations(["showEveryDisplay"]),
    ...mapActions(["wipeChat"]),
    handleExpertSelected(expert) {
      console.log('Expert selected:', expert);
      this.currentStep = 'chat'; // Move to chat step
    },
    backToExpertSelection() {
      this.currentStep = 'selectExpert';
      this.wipeChat();
    }
  },
  mounted() {
    this.$store.state.showNavbar = true;
    this.showEveryDisplay();
  }
};
</script>

<style scoped>
.footer, .navbar-main {
  display: none !important;
}


</style>
