import { createStore } from "vuex";
import { authService } from '@/auth/authService';
const { VUE_APP_MAIN_API_URL } = process.env;
import createPersistedState from 'vuex-persistedstate';
import OpenAI from '@/assets/img/logos/OpenAI.png';
import GymImage from "@/assets/img/illustrations/gym-dynamic-gradient.png";

export default createStore({
  state: {
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    sidebarType: "bg-white",
    isRTL: false,
    color: "primary",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: false,
    showNavbar: false,
    showFooter: false,
    showMain: true,
    isDarkMode: false,
    navbarFixed:
      "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "px-4 mx-0 w-100 z-index-2",
    isAuthenticated: false,
    accessToken: "",
    code: "",
    experts: [],
    selectedExpert: null,
    selectedAbility: null,
    user: null,
    chatMessages: [],
    isLoading: false,
    isBackgroundLoading: false,
    isErrorInRequest: false,
    unsavedChanges: false,
    selectedModel: null,
    selectedTemplateText: '',
    selectedConversationId: null,
    isSidebarOpen: false,
    author: null,
    models: [],
    trainingSessions: [],
    selectedTrainingSession: null,
    currentStatusMessage: "Start Training",
    shouldAnimate: false,
    showCancel: false,
    statusCheckInterval: null,
    jobId: null,
    qaPairs: [],
    statistics: [],
    traininsSessionQaPairs: [],
    chatSettings: {
      temperature: 0.7,
      top_p: 0.1,
      private_session: false,
    },
    toolActivation: {
      tavily_search: true,
      wolfram_alpha: true,
      document_retriever: true,
      notion_connector: true
    },
    notifications: [],
    generatedCards: [],
    apps: {
      tavily_search: {
        icon: 'tavily',
        name: 'Tavily Search',
        actions: {}
      },
      wolfram_alpha: {
        icon: 'wolfram',
        name: 'Wolfram Alpha',
        actions: {}
      },
      document_retriever: {
        icon: 'file-text',
        name: 'Document Retriever',
        actions: {}
      },
      notion_connector: {
        icon: 'notion',
        name: 'Notion Connector',
        actions: {
          sync: {
            name: 'Sync',
            description: 'Synchronize your database with B-Bot',
          },
        },
      },
      upstash_redis_private: {
        icon: 'upstash_redis',
        name: 'Upstash Redis Private',
        actions: {}
      },
      redis_private: {
        icon: 'redis',
        name: 'Redis Private',
        actions: {}
      }
    },
    trainingInProgress: false,
    trainingSuccess: false,
    selectedModelForAgentCreation: {
      src: OpenAI,
      alt: "OpenAI logo",
      type: "OpenAI-GPT-3.5-Turbo",
      providers: ["OpenAI", "Azure"],
      selectedProvider: "OpenAI",
      isDisabled: false
    },
    selectedProviderForAgentCreation: 'OpenAI',
  },
  mutations: {
    setModelForAgentCreation(state, model) {
        state.selectedModelForAgentCreation = model;
    },
    setProviderForAgentCreation(state, provider) {
        state.selectedProviderForAgentCreation = provider;
    },
    setTrainingInProgress(state, status) {
      state.trainingInProgress = status;
    },
    setTrainingSuccess(state, status) {
      state.trainingSuccess = status;
    },
    addNotification(state, notification) {
      state.notifications.push(notification);
    },
    updateNotification(state, updatedNotification) {
      const index = state.notifications.findIndex(
          (notification) => notification.id === updatedNotification.id
      );
      if (index !== -1) {
        state.notifications.splice(index, 1, updatedNotification);
      }
    },
    removeNotification(state, notificationId) {
      state.notifications = state.notifications.filter(
          (notification) => notification.id !== notificationId
      );
    },
    clearNotifications(state) {
      state.notifications = [];
    },
    setStatusMessage(state, message) {
      console.log("message", message)
      state.currentStatusMessage = message;
    },
    setShouldAnimate(state, value) {
      state.shouldAnimate = value;
    },
    setShowCancel(state, value) {
      console.log("SHOW CANCEL: ", value)
      state.showCancel = value;
    },
    setStatusInterval(state, intervalId) {
      state.statusCheckInterval = intervalId;
    },
    clearStatusInterval(state) {
      if (state.statusCheckInterval) {
        clearInterval(state.statusCheckInterval);
        state.statusCheckInterval = null;
      }
    },
    setJobId(state, id) {
      console.log("MUTATION JOB ID: ", id)
      state.jobId = id;
    },
    clearJobId(state) {
      state.jobId = null;
    },
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");

      if (sidenav_show.classList.contains("g-sidenav-pinned")) {
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    hideEveryDisplay(state) {
      state.showNavbar = false;
      state.showSidenav = false;
      state.showFooter = false;
    },
    showEveryDisplay(state) {
      state.showNavbar = true;
      state.showSidenav = true;
      state.showFooter = true;
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },
    hideConfig(state) {
      state.hideConfigButton = true;
    },
    color(state, payload) {
      state.color = payload;
    },
    setAuthStatus(state, status) {
      state.isAuthenticated = status;
    },
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken; // Assuming you have an accessToken state
    },
    setCode(state, code) {
      state.code = code; // Assuming you have an accessToken state
    },
    setUser(state, user) {
      state.user = user; // Assuming you have an accessToken state
    },
    setExperts(state, experts) {
      state.experts = experts;
    },
    setSelectedExpert(state, expert) {
      state.selectedAbilityId = null;
      state.selectedExpert = expert;
      state.selectedModel = expert.attributes.expert_llm_models.data.slice(-1)[0]
      console.log("Last SELECTED", state.selectedModel);
    },
    setSelectedAbility(state, ability) {
      state.selectedAbility = ability;
    },
    pushMessage(state, message) {
      state.chatMessages.push(message);
    },
    wipeChat(state) {
      state.chatMessages = [];
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsBackgroundLoading(state, isLoading) {
      state.isBackgroundLoading = isLoading;
    },
    setIsErrorInRequest(state, isError) {
      state.isErrorInRequest = isError;
    },
    setUnsavedChanges(state, hasChanges) {
      state.unsavedChanges = hasChanges;
    },
    setSelectedModel(state, model) {
      console.log("MODEL SELECTION DEBUGGING: ", model)
      state.selectedModel = model;
    },
    setSelectedTemplateText(state, text) {
      state.selectedTemplateText = text;
    },
    setSelectedConversation(state, conversation) {
      state.selectedConversationId = conversation.id;
      state.chatMessages = conversation.attributes.messages;
    },
    addExpert(state, expert) {
      state.experts.push(expert);
    },
    setGeneratedCards(state, cards) {
        state.generatedCards = cards;
    },
    removeExpert(state, expert) {
      const expertId = expert.id;
      const index = state.experts.findIndex(expert => expert.id === expertId);
      if (index !== -1) {
        state.experts.splice(index, 1);
      }
    },
    deleteTemplate(state, { expertId, templateId }) {
      // Find the expert by its ID
      const expertIndex = state.experts.findIndex(expert => expert.id === expertId);
      if (expertIndex === -1) {
        console.error('Expert not found');
        return;
      }

      // Find the template by its ID within the found expert's templates
      const templateIndex = state.experts[expertIndex].attributes.templates.data.findIndex(template => template.id === templateId);
      if (templateIndex !== -1) {
        // Remove the template from the expert's templates array
        state.experts[expertIndex].attributes.templates.data.splice(templateIndex, 1);
      } else {
        console.error('Template not found');
      }
    },
    deleteAbility(state, { expertId, abilityId }) {
      // Find the expert by its ID
      const expertIndex = state.experts.findIndex(expert => expert.id === expertId);
      if (expertIndex === -1) {
        console.error('Expert not found');
        return;
      }

      // Find the template by its ID within the found expert's templates
      const abilityIndex = state.experts[expertIndex].attributes.abilities.data.findIndex(ability => ability.id === abilityId);
      if (abilityIndex !== -1) {
        // Remove the template from the expert's templates array
        state.experts[expertIndex].attributes.templates.data.splice(abilityIndex, 1);
      } else {
        console.error('Template not found');
      }
    },
    toggleSidebar(state) {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
    updateUserMetadataFromApps(state, { key, value }) {
      state.user.hub_user_metadata.apps = {
        ...state.user.hub_user_metadata.apps,
        [key]: value
      };
    },
    deleteUserMetadataFromApps(state, key) {
      delete state.user.hub_user_metadata.apps[key];
    },
    updateUserMetadata(state, { key, value }) {
      console.log("key", key);
      console.log("value", value);
      // Ensure this does not add unwanted top-level properties
      state.user.hub_user_metadata = {
        ...state.user.hub_user_metadata,
        [key]: value
      };
    },
    setModels(state, models) {
      state.models = models;
    },
    setAuthor(state, author) {
      state.author = author;
    },
    addTrainingSession(state, session) {
      state.trainingSessions.push(session);
    },
    updateTrainingSessionMetadata(state, { sessionId, key, value }) {
      console.log(state, sessionId, key, value)
      /*
      const sessionIndex = state.trainingSessions.findIndex(session => session.id === sessionId);
      if (sessionIndex !== -1) {
        state.trainingSessions[sessionIndex][key] = value;
      }
      */
    },
    deleteTrainingSession(state, sessionId) {
      const index = state.trainingSessions.findIndex(session => session.id === sessionId);
      if (index !== -1) {
        state.trainingSessions.splice(index, 1);
      }
    },
    setTrainingSessions(state, sessions) {
      state.trainingSessions = sessions;
    },
    saveTrainingSession(state, session) {
      console.log("save Training Session and its data", session)
      state.selectedTrainingSession = session; // Assuming you have this state property
    },
    setSelectedTrainingSession(state, session) {
      state.selectedTrainingSession = session;
    },
    addQAPair(state, qaPair) {
      // Assuming `qaPairs` is an array within your state to store QA pairs
      state.qaPairs.push(qaPair);
    },
    updateQAPairInState(state, { qaId, qaPairs }) {
      const index = state.qaPairs.findIndex(pair => pair.id === qaId);
      if (index !== -1) {
        // Assuming each QA pair in your state has a unique ID
        state.qaPairs[index].messages = qaPairs;
      }
    },
    wipeQAPairState(state){
      state.qaPairs = [];
    },
    deleteQAPairFromState(state, qaId) {
      const index = state.qaPairs.findIndex(pair => pair.id === qaId);
      if (index !== -1) {
        state.qaPairs.splice(index, 1);
      }
    },
    toggleQAPairSelection(state, qaId) {
      const index = state.qaPairs.findIndex(qaPair => qaPair.id === qaId);
      if (index !== -1) {
        state.qaPairs[index].selected = !state.qaPairs[index].selected;
      }
    },
    setStatistics(state, statistics) {
        state.statistics = statistics;
    },
    addStatistics(state, statistic) {
        state.statistics.push(statistic);
    },
    updateStatistics(state, { statisticId, updatedData }) {
        const index = state.statistics.findIndex(statistic => statistic.id === statisticId);
        if (index !== -1) {
            state.statistics[index] = {
                ...state.statistics[index],
                ...updatedData
            };
        }
    },
    updateChatSettings(state, { setting, value }) {
      // Safer check for property existence
      if (Object.prototype.hasOwnProperty.call(state.chatSettings, setting)) {
        state.chatSettings[setting] = value;
      }
    },
    toggleToolActivation(state, { tool, value }) {
      // Use the same type of check for safe property modification
      if (Object.prototype.hasOwnProperty.call(state.toolActivation, tool)) {
        state.toolActivation[tool] = value;
      }
    },
    clearSelectedTraininsSessionQaPairs(state) {
        state.traininsSessionQaPairs = [];
    }
  },
  actions: {
    setProviderForAgentCreation({ commit }, provider) {
        commit('setProviderForAgentCreation', provider);
    },
    setModelForAgentCreation({ commit }, model) {
        commit('setModelForAgentCreation', model);
    },
    trainingSuccess({ commit }, status) {
        commit('setTrainingSuccess', status);
    },
    startTrainingInProgress({ commit }) {
      commit('setTrainingInProgress', true);
    },
    stopTrainingInProgress({ commit }) {
      commit('setTrainingInProgress', false);
    },
    addNotification({ commit }, notification) {
      commit('addNotification', notification);
    },
    removeNotification({ commit }, notificationId) {
      commit('removeNotification', notificationId);
    },
    clearNotifications({ commit }) {
      commit('clearNotifications');
    },
    setColor({ commit }, payload) {
      commit("color", payload);
    },
    setIsLoading({ commit }, payload){
      commit("setIsLoading", payload);
    },
    setIsBackgroundLoading({ commit }, payload){
      commit("setIsBackgroundLoading", payload);
    },
    setIsErrorInRequest({ commit }, isError) {
      commit('setIsErrorInRequest', isError);
    },
    toggleSidebar({ commit }) {
      commit('toggleSidebar');
    },
    selectAbility({ commit }, ability) {
      commit('setSelectedAbility', ability);
    },
    setGeneratedCards({ commit }, cards) {
        commit('setGeneratedCards', cards);
    },
    startStatusPolling({ dispatch, commit }, { fineTuningId, provider }) {
      let time = 10000;
      commit('clearStatusInterval'); // Clear any existing interval
      console.log("status polling start!")
      const intervalId = setInterval(async () => {
        console.log("provider:::", provider)
        const data = await dispatch('checkTrainingSessionStatus', { fineTuningId, provider});
        if (data && data.status) {
          let status = data.status.status;
          console.log("status polling!")
          console.log("status", status)

          if (provider === "tuneai" && status === 'ACTIVE') {
            status = 'running';
          } else if (provider === "tuneai" && status === 'PROVISIONING') {
            status = 'queued';
          } else if (provider === "tuneai" && status === 'COMPLETED') {
            status = 'succeeded';
          }

          dispatch('updateUIBasedOnStatus', status);
          dispatch('updateModelBasedOnStatus', { status, data, provider });
          if (status === 'succeeded' || status === 'failed' || status === 'cancelled') {
            commit('clearStatusInterval');
            commit('fetchModels')
          }
        }
      }, time);

      commit('setStatusInterval', intervalId);
    },

    updateUIBasedOnStatus({ commit, state }, status) {
      console.log("updateUIBasedOnStatus!")

      let message = "";
      let animate = false;

      switch (status) {
        case 'ready':
          message = "Start Training";
          commit('setStatusMessage', message);
          commit('setShowCancel', false);

          animate = false;
          break;
        case 'validating_files':
          message = "Validate Training Data...";
          commit('setShowCancel', true);

          commit('setStatusMessage', message);

          animate = true;
          break;
        case 'queued':
          message = "Process in Queue...";
          commit('setShowCancel', true);

          animate = true;
          commit('setStatusMessage', message);

          break;
        case 'pending':
          message = "Process in Queue...";
          commit('setShowCancel', true);

          animate = true;
          commit('setStatusMessage', message);

          break;
        case 'cancelled':
          message = "Cancelled... Restart?";
          commit('setShowCancel', false);
          commit('clearStatusInterval');
          commit('setStatusMessage', message);

          break;
        case 'running':
          message = "Training in progress...";
          animate = true;
          commit('setShowCancel', true);

          commit('setStatusMessage', message);

          break;
        case 'deployment_in_progress':
          message = "Deployment in progress...";
          animate = true;
          commit('setStatusMessage', message);
          commit('setShowCancel', true);

          break;
        case 'succeeded':
          message = "Training Completed";
          commit('clearStatusInterval');
          commit('setStatusMessage', message);
          commit('setTrainingSuccess', true);
          commit('setTrainingInProgress', false);
          commit('setShowCancel', false);

          break;
        case 'failed':
          message = "Training Failed";
          commit('clearStatusInterval');
          commit('setStatusMessage', message);
          commit('setTrainingSuccess', false);
          commit('setTrainingInProgress', false);
          commit('setShowCancel', false);

          break;
        default:
          message = "Unknown Status";
          commit('setTrainingInProgress', false);
          commit('clearStatusInterval');
          commit('setShowCancel', false);

          break;
      }

      const existingNotification = state.notifications.find(
          (notification) => notification.type === 'training_status'
      );

      if (existingNotification) {
        const updatedNotification = {
          ...existingNotification,
          title: message,
          link: '/training#train',
          time: new Date().toLocaleTimeString(),
        };
        commit('updateNotification', updatedNotification);
      } else {
        // Add new notification if none exists
        const notification = {
          id: new Date().getTime(), // Unique ID based on timestamp
          type: 'training_status', // Unique type for training status notifications
          title: message,
          image: GymImage, // Optional: add an image if necessary
          link: '/training#train', // Optional: add a link to the notification
          time: new Date().toLocaleTimeString()
        };
        commit('addNotification', notification);
      }

      commit('setStatusMessage', message);
      commit('setShouldAnimate', animate);
      //commit('setShowCancel', showCancel);
    },

    async updateModelBasedOnStatus({ dispatch, state }, { status, data, provider }) {
      if (status === 'succeeded') {
        console.log("adding training session: ",  state.selectedTrainingSession.id)
        let newModelData = {};
        if (provider === "tuneai") {
          newModelData = {
            name: "Gen_" + data.status.id,
            identifier: "beyondbotai/"+data.status.name+"-model-" + data.status.id,
            models_experts: state.selectedExpert.id,
            previous_models: state.selectedModel.id,
            training_sessions: state.selectedTrainingSession.id,
            provider: "tuneai",
            multiplier: state.selectedTrainingSession.mto.multiplier,
          };
        } else {
          newModelData = {
            name: "Gen_" + data.status.created_at,
            identifier: data.status.fine_tuned_model,
            models_experts: state.selectedExpert.id,
            previous_models: state.selectedModel.id,
            training_sessions: state.selectedTrainingSession.id,
            provider: state.selectedTrainingSession.mto.provider,
            multiplier: state.selectedTrainingSession.mto.multiplier,
          };
        }
        await dispatch('createModel', newModelData);
        //trying to add the new model to the training session so we can later fetch it based on the training session
        console.log("Adding model to training Session, FINISHED TRAINING!", newModelData)
        await dispatch('updateTrainingSession' , { llm_models: state.models[0].id });
        await dispatch('stopTrainingInProgress');
        await dispatch('fetchExperts');
        await dispatch('fetchModels');
      }
    },
    async checkAuthStatus({ commit, state }) {
      // Example of a condition to avoid unnecessary checks
      // This could be more sophisticated based on your routing and authentication logic
      if (!state.isAuthenticated && !localStorage.getItem('isAuthenticated')) {
        try {
          const isAuthenticated = await authService.isAuthenticated();

          // Cache the authentication status to avoid unnecessary checks
          localStorage.setItem('isAuthenticated', isAuthenticated);
          commit('setAuthStatus', isAuthenticated);
        } catch (error) {
          commit('setAuthStatus', false); // Set isAuthenticated to false in case of error
          // Clear any cached authentication status
          localStorage.removeItem('isAuthenticated');
        }
      }
    },
    async updateUserMetadata({ commit, state }, { metadataKey, newValue }) {
      const accessToken = state.user.accessToken; // Assuming accessToken is stored in the user object
      const updateData = {
        user_metadata: {
          ...state.user.hub_user_metadata,
          [metadataKey]: newValue
        }
      };

      try {
        const response = await fetch('/.netlify/functions/updateUserInfo', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          },
          body: JSON.stringify(updateData)
        });

        if (response.ok) {
          commit('updateUserMetadata', { key: metadataKey, value: newValue });
        } else {
          console.error('Failed to update user metadata:', await response.text());
        }
      } catch (error) {
        console.error('Error updating user metadata:', error);
      }
    },
    async updateUserMetadataApp({ commit, state }, { key, value }) {
      const accessToken = state.user.accessToken;

      console.log("metadataKey", key);
      console.log("settings", value);

      // Prepare the update data including the specific app settings
      const updateData = {
        user_metadata: {
          ...state.user.hub_user_metadata,
          apps: {
            ...state.user.hub_user_metadata.apps,
            [key]: {
              ...value // Update with new settings, no need to preserve existing metadata here
            }
          }
        }
      };
      console.log("updateData", updateData)
      try {
        const response = await fetch('/.netlify/functions/updateUserInfo', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          },
          body: JSON.stringify(updateData)
        });

        if (response.ok) {
          // Update Vuex store with the new metadata
          commit('updateUserMetadataFromApps', { key, value });
        } else {
          console.error('Failed to update user metadata:', await response.text());
        }
      } catch (error) {
        console.error('Error updating user metadata:', error);
      }
    },
    async deleteUserMetadataApp({ commit, state }, key) {
      const accessToken = state.user.accessToken;

      // Filter out the key from the apps object
      const updatedApps = Object.keys(state.user.hub_user_metadata.apps)
          .filter(appKey => appKey !== key)
          .reduce((obj, appKey) => {
            obj[appKey] = state.user.hub_user_metadata.apps[appKey];
            return obj;
          }, {});

      const updateData = {
        user_metadata: {
          ...state.user.hub_user_metadata,
          apps: updatedApps
        }
      };

      try {
        const response = await fetch('/.netlify/functions/updateUserInfo', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          },
          body: JSON.stringify(updateData)
        });

        if (response.ok) {
          // Update Vuex store with the new metadata
          commit('deleteUserMetadataFromApps', key);
        } else {
          console.error('Failed to delete user metadata:', await response.text());
        }
      } catch (error) {
        console.error('Error deleting user metadata:', error);
      }
    },
    async fetchModels({ commit }) {
      try {
        const fetchResponse = await fetch(`${window.location.origin}/.netlify/functions/datacenter`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            callname: "llm-models?populate=*", // Adjust this endpoint as needed
            action: "GET"
          }),
        });

        if (!fetchResponse.ok) {
          throw new Error(`Error: ${fetchResponse.statusText}`);
        }

        const data = await fetchResponse.json();
        const models = data.data;

        // Store only the first author, if exists
        if (models.length > 0) {
          commit('setModels', models);
        }

      } catch (error) {
        console.error('Fetching the author failed:', error);
      }
    },
    async fetchTrainingSessions({ commit }) {
      try {
        const fetchResponse = await fetch(`${window.location.origin}/.netlify/functions/datacenter`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            callname: "training-sessions?populate=*", // Adjust this endpoint as needed
            action: "GET"
          }),
        });

        if (!fetchResponse.ok) {
          throw new Error(`Error: ${fetchResponse.statusText}`);
        }

        const data = await fetchResponse.json();
        const trainingSessions = data.data;

        // Store only the first author, if exists
        if (trainingSessions.length > 0) {
          commit('setTrainingSessions', trainingSessions);
        }

      } catch (error) {
        console.error('Fetching the author failed:', error);
      }
    },
    async fetchAuthor({ commit }) {
      try {
        const fetchResponse = await fetch(`${window.location.origin}/.netlify/functions/datacenter`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            callname: "authors?populate=*", // Adjust this endpoint as needed
            action: "GET"
          }),
        });

        if (!fetchResponse.ok) {
          throw new Error(`Error: ${fetchResponse.statusText}`);
        }

        const data = await fetchResponse.json();
        const authors = data.data;

        // Store only the first author, if exists
        if (authors.length > 0) {
          commit('setAuthor', authors[0]);
        }

      } catch (error) {
        console.error('Fetching the author failed:', error);
      }
    },
    async editAuthorOfUser({ dispatch }, { authorId, authorData }) {
      try {
        const fetchResponse = await fetch(`${window.location.origin}/.netlify/functions/datacenter`, {
          method: 'PUT', // Assuming PUT is used for updates; adjust as needed
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            callname: "authors/" + authorId,
            action: "PUT",
            payload: { data: authorData },
          }),
        });

        if (!fetchResponse.ok) {
          const errorResponse = await fetchResponse.json();
          return { success: false, error: errorResponse };
        }

        const responseData = await fetchResponse.json();
        await dispatch('fetchAuthor'); // Refresh the authors list, or similar action
        return { success: true, data: responseData.data };
      } catch (error) {
        console.error('Error editing author:', error);
        return { success: false, error: { message: error.message } };
      }
    },
    async fetchExperts({ commit }) {
      try {
        const fetchResponse = await fetch(window.location.origin + '/.netlify/functions/datacenter', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            callname: "experts?populate=*&publicationState=preview",
            action: "GET"
          }),
        });
        if (!fetchResponse.ok) {
          throw new Error(`Error: ${fetchResponse.statusText}`);
        }
        const data = await fetchResponse.json();

        // Iterate through each expert to modify templates and abilities
        const expertsWithEditing = data.data.map(expert => {
          const templates = expert.attributes.templates.data.map(template => ({
            ...template,
            isEditing: false,
          }));
          const abilities = expert.attributes.abilities.data.map(ability => ({
            ...ability,
            isEditing: false,
          }));
          return {
            ...expert,
            attributes: {
              ...expert.attributes,
              templates: { data: templates },
              abilities: { data: abilities },
            },
          };
        });
        console.log("expertsWithEditing", expertsWithEditing)
        commit('setExperts', expertsWithEditing);
        commit('setSelectedExpert', expertsWithEditing[0]);

      } catch (error) {
        console.error('Fetching experts failed:', error);
      }
    },
    async createExpert({ dispatch }, expertData) {
      try {
        const fetchResponse = await fetch(window.location.origin + '/.netlify/functions/datacenter', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            callname: "experts",
            action: "POST",
            payload: { data: expertData },
          }),
        });

        if (!fetchResponse.ok) {
          const errorResponse = await fetchResponse.json();
          // If you want to handle validation errors specifically, you might return them here
          return { success: false, error: errorResponse }; // Return error information
        }

        const responseData = await fetchResponse.json();

        // If the expert is successfully created, refetch the experts list
        await dispatch('fetchExperts');
        await dispatch('fetchModels');

        // Optionally, you might want to select the newly created expert as the selectedExpert
        // commit('setSelectedExpert', responseData.data);

        return { success: true, data: responseData.data }; // Indicate success and return the new expert data

      } catch (error) {
        console.error('Error creating expert:', error);
        return { success: false, error: { message: error.message } }; // Return error information
      }
    },
    async createModel({ dispatch }, modelData){
      try {
        const fetchResponse = await fetch(window.location.origin + '/.netlify/functions/datacenter', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            callname: "llm-models",
            action: "POST",
            payload: { data: modelData },
          }),
        });

        if (!fetchResponse.ok) {
          const errorResponse = await fetchResponse.json();
          // If you want to handle validation errors specifically, you might return them here
          return { success: false, error: errorResponse }; // Return error information
        }

        const responseData = await fetchResponse.json();

        // If the expert is successfully created, refetch the experts list
        await dispatch('fetchModels');

        // Optionally, you might want to select the newly created expert as the selectedExpert
        // commit('setSelectedExpert', responseData.data);

        return { success: true, data: responseData.data }; // Indicate success and return the new expert data

      } catch (error) {
        console.error('Error creating expert:', error);
        return { success: false, error: { message: error.message } }; // Return error information
      }
    },
    async updateModel({ dispatch }, { modelId, modelData }){
      try {
        console.log("Model Data:", modelData)
        const fetchResponse = await fetch(window.location.origin + '/.netlify/functions/datacenter', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            callname: "llm-models/" + modelId,
            action: "PUT",
            payload: { data: modelData },
          }),
        });

        if (!fetchResponse.ok) {
          const errorResponse = await fetchResponse.json();
          // If you want to handle validation errors specifically, you might return them here
          return { success: false, error: errorResponse }; // Return error information
        }

        const responseData = await fetchResponse.json();
        console.log("Model Updated", responseData)
        // If the expert is successfully created, refetch the experts list
        await dispatch('fetchModels');

        // Optionally, you might want to select the newly created expert as the selectedExpert
        // commit('setSelectedExpert', responseData.data);

        return { success: true, data: responseData.data }; // Indicate success and return the new expert data

      } catch (error) {
        console.error('Error creating expert:', error);
        return { success: false, error: { message: error.message } }; // Return error information
      }
    },
    async getUser({ commit }) {
      try {
        const user = await authService.getUser();
        commit('setUser', user);
      } catch (error) {
        console.error('Fetching user failed:', error);
        // Handle any errors, such as network issues or API errors
      }
    },
    async saveChat({ commit, state }) {
      // Check if there's anything to save
      if (state.chatMessages.length === 0) {
        console.log("No chat messages to save");
        return;
      }

      if(state.selectedConversationId === null){
        try {
          const fetchResponse = await fetch(window.location.origin + '/.netlify/functions/datacenter', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              callname: "conversations",
              action: "POST",
              payload: { // The payload expected by your Netlify function
                data: { // The data wrapper expected by Strapi
                  name: state.chatMessages[0].text.substring(0, 50),
                  messages: state.chatMessages,
                  expert: state.selectedExpert?.id,
                }
              }
            }),
          });

          if (!fetchResponse.ok) {
            throw new Error(`Error saving chat: ${fetchResponse.statusText}`);
          }

          const responseData = await fetchResponse.json();
          console.log("Chat saved successfully:", responseData);

          // Clear chat messages in Vuex store and localStorage after saving
          //commit('wipeChat');
          localStorage.setItem('chatMessages', JSON.stringify([]));
          localStorage.setItem('unsavedChanges', 'false');
          commit('setUnsavedChanges', false);

        } catch (error) {
          console.error('Error saving chat:', error);
        }
      } else{
        try {
          const fetchResponse = await fetch(window.location.origin + '/.netlify/functions/datacenter/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              callname: "conversations/"+ state.selectedConversationId,
              action: "PUT",
              payload: {
                data: {
                  messages: state.chatMessages,
                }
              }
            }),
          });

          if (!fetchResponse.ok) {
            throw new Error(`Error saving chat: ${fetchResponse.statusText}`);
          }

          const responseData = await fetchResponse.json();
          console.log("Chat saved successfully:", responseData);

          // Clear chat messages in Vuex store and localStorage after saving
          //commit('wipeChat');
          localStorage.setItem('chatMessages', JSON.stringify([]));
          localStorage.setItem('unsavedChanges', 'false');
          commit('setUnsavedChanges', false);

        } catch (error) {
          console.error('Error saving chat:', error);
        }
      }

    },
    saveMessage({ commit, state }, message) {
      commit('pushMessage', message);
      // Also save the updated chatMessages to localStorage
      localStorage.setItem('chatMessages', JSON.stringify(state.chatMessages));
      // Mark unsavedChanges as true
      commit('setUnsavedChanges', true);
      localStorage.setItem('unsavedChanges', 'true');
    },
    wipeChat({ commit }) {
      commit('wipeChat');
    },
    updateUnsavedChanges({ commit }, hasChanges) {
      commit('setUnsavedChanges', hasChanges);
    },
    async addTrainingSession({ commit, state }, { trainingData, name, description }) {
      try {
        console.log("ADD TRAINING SESSION: ", state.selectedModel)
        const response = await fetch(`${window.location.origin}/.netlify/functions/datacenter`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            callname: "training-sessions?populate=mto",
            action: "POST",
            payload: {
              data: {
                mto: state.selectedModel,
                name: name,
                description: description,
                training_data: { session: trainingData}
              },populate: "*",
            }
          })
        });

        if (!response.ok) {
          console.error('Error adding training session');
          return;
        }

        const responseData = await response.json();
        //commit('addTrainingSession', sessionData); // Call the mutation with the session data
        commit('saveTrainingSession', responseData);
        return responseData;
      } catch (error) {
        console.error('Error adding training session:', error);
      }
    },
    async updateTrainingSession({ commit, dispatch }, { sessionId, data }) {
      try {
        console.log("UPDATE TRAINING SESSION: ", sessionId, data)
        const response = await fetch(`${window.location.origin}/.netlify/functions/datacenter`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            callname: "training-sessions/" + sessionId + "?populate=mto",
            action: "PUT",
            payload: { data, populate: "mtos", }
          })
        });

        if (!response.ok) {
          throw new Error('Failed to update session metadata');
        }
        dispatch('getTrainingSession', sessionId);
        commit('updateTrainingSessionMetadata', { sessionId, data });
      } catch (error) {
        console.error('Error updating training session metadata:', error);
      }
    },
    async deleteTrainingSession({ commit }, sessionId) {
      try {
        // API-Aufruf, um die Session aus dem Backend zu löschen
        const response = await fetch(`${window.location.origin}/.netlify/functions/datacenter`, {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            callname: "training-sessions/" + sessionId,
            action: "DELETE",
          })
        });

        if (!response.ok) {
          console.error('Error deleting training session');
          return;
        }

        commit('deleteTrainingSession', sessionId);

      } catch (error) {
        console.error('Error deleting training session:', error);
      }
    },
    async getTrainingSession({ commit }, sessionId) {
      try {
        console.log("GET TRAINING SESSION: ", sessionId)
        // API-Aufruf, um die Session aus dem Backend zu löschen
        const response = await fetch(`${window.location.origin}/.netlify/functions/datacenter`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            callname: "training-sessions/" + sessionId + "?populate=mto" ,
            action: "GET",
          })
        });

        if (!response.ok) {
          console.error('Error deleting training session');
          return;
        }
        const responseData = await response.json();
        console.log("fetch trough the sessions here again", responseData);
        commit('saveTrainingSession', responseData );
        return responseData;
      } catch (error) {
        console.error('Error deleting training session:', error);
      }
    },
    saveTrainingSession({ commit }, session) {
      commit('saveTrainingSession', session);
    },
    async listTrainingSessions({ commit }) {
      try {
        const response = await fetch(`${window.location.origin}/.netlify/functions/datacenter`, {
          method: 'POST', // Adjust if necessary
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            callname: "training-sessions?populate=mto",
            action: "GET",
          })
        });

        if (!response.ok) {
          throw new Error('Failed to fetch training sessions');
        }

        const sessions = await response.json();
        console.log("fetch trough the sessions here again", sessions);
        commit('setTrainingSessions', sessions);
        // Optionally set a selected session if not already set
        if (!this.state.selectedTrainingSession && sessions.length) {
          commit('setSelectedTrainingSession', sessions[0]); // Automatically select the first session for demonstration
        }
      } catch (error) {
        console.error('Error listing training sessions:', error);
      }
    },
    async startTrainingSession({ dispatch, state }, trainingPayload) {
      const characterPrompt = state.selectedExpert.attributes.system_message;

      let abilityPrompt = '';
      if (state.selectedAbility) {
        abilityPrompt = state.selectedAbility.attributes.text;
      } else {
        console.log('No ability selected, skipping abilityPrompt');
      }
      console.log("selectedTrainingSession: ",state.selectedTrainingSession)
      const modifiedPayload = {
        model: state.selectedTrainingSession.mto.identifier,
        provider: state.selectedTrainingSession.mto.provider,
        ...trainingPayload,
        characterPrompt,
        ...(abilityPrompt && { abilityPrompt }),
      };

      try {
        const responseData = await dispatch('callApiFunction', {
          functionName: "trainllm",
          payload: modifiedPayload,
        });

        if (responseData) {
          console.log('Training session started:', responseData)
          // Use the fine_tuning_id from responseData as the session ID
          const sessionId = state.selectedTrainingSession.id;
          console.log("sessionId in Start Training session: ", sessionId)
          // You may want to include more session data here based on your needs
          const sessionData = {
            status: {
              id: responseData.fine_tuning_id,
            }
          };
          // Call updateTrainingSession to save/update the session metadata
          await dispatch('updateTrainingSession', { sessionId, data: {training_session_data: sessionData} });
          return sessionData;
        } else {
          console.error('Failed to start training session');
        }
      } catch (error) {
        console.error('Error during training session start:', error);
      }
    },
    async stopTrainingSession({ dispatch, state }){
      try {

        const id = state.jobId;
        const provider = state.selectedTrainingSession.mto.provider
        // Ensure the function is using 'GET' method instead of 'POST'
        const response = await fetch(`${VUE_APP_MAIN_API_URL}/api/v0/trainllm/cancel/${id}/${provider}`, {
          method: 'GET', // Corrected to GET method
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data && data.status) {
          dispatch('updateTrainingSession', { sessionId: state.selectedTrainingSession.id, data: { training_session_data: data } });
        }

        return data; // Return the data for any further processing
      } catch (error) {
        console.error('Failed to check training session status:', error);
        throw error; // Rethrow or handle error as appropriate
      }
    },
    async checkTrainingSessionStatus({ dispatch, state }, { fineTuningId, provider }) {
      try {
        console.log(state.selectedTrainingSession.experts[0].id);
        // Ensure the function is using 'GET' method instead of 'POST'
        const response = await fetch(`${VUE_APP_MAIN_API_URL}/api/v0/trainllm/status/${state.user.sub}/${state.selectedTrainingSession.experts[0].id}/${fineTuningId}/${provider}`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data && data.status) {
          dispatch('updateTrainingSession', { sessionId: state.selectedTrainingSession.id, data: { training_session_data: data } });
        }

        return data; // Return the data for any further processing
      } catch (error) {
        console.error('Failed to check training session status:', error);
        throw error; // Rethrow or handle error as appropriate
      }
    },
    async saveQAPairs({ commit }, { qaPairs }) {
      try {
        // Convert QA pairs to the format expected by your backend
        const payload = {
          name: "test",
          tags: [{name: "test1", value: "test1"},{name: "test2", value: "test2"}],
          set: qaPairs
        };

        const response = await fetch(`${window.location.origin}/.netlify/functions/datacenter`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: JSON.stringify({
            callname: "qas",
            action: "POST",
            payload: { data: payload }
          })
        });

        if (!response.ok) {
          throw new Error('Failed to save QA pairs');
        }

        const responseData = await response.json();
        console.log(responseData);
        // Optionally, commit a mutation to update state with new QA pair data
        qaPairs.forEach(qaPair => commit('addQAPair', qaPair));
        commit("fetchQAPairs");
      } catch (error) {
        console.error('Error saving QA pairs:', error);
      }
    },
    async updateQAPair({ commit }, { qaId, qaPairs }) {
      try {
        const payload = { messages: qaPairs };
        const response = await fetch(`${window.location.origin}/.netlify/functions/datacenter`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: JSON.stringify({
            callname: `qas/${qaId}`,
            action: "PUT",
            payload: payload
          })
        });

        if (!response.ok) {
          throw new Error('Failed to update QA pair');
        }

        const responseData = await response.json();
        console.log(responseData);

        commit('updateQAPairInState', { qaId, qaPairs }); // Update local state
      } catch (error) {
        console.error('Error updating QA pair:', error);
      }
    },
    async deleteQAPair({ commit }, qaId) {
      try {
        const response = await fetch(`${window.location.origin}/.netlify/functions/datacenter`, {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',

          body: JSON.stringify({
            callname: `qas/${qaId}`,
            action: "DELETE",
          })
        });

        if (!response.ok) {
          throw new Error('Failed to delete QA pair');
        }

        commit('deleteQAPairFromState', qaId); // Remove from local state
      } catch (error) {
        console.error('Error deleting QA pair:', error);
      }
    },
    async fetchQAPairs({ commit }) {
      try {
        const response = await fetch(`${window.location.origin}/.netlify/functions/datacenter`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: JSON.stringify({
            callname: `qas`,
            action: "GET",
          })
        });

        if (!response.ok) {
          throw new Error('Failed to fetch QA pairs');
        }

        const responseData = await response.json();
        // Assuming responseData contains an array of QA pairs
        // Augment each QA pair with a selected attribute set to false
        const qaPairsWithSelected = responseData.data.map(qaPair => ({
          ...qaPair,
          selected: false // Add the selected attribute here
        }));
        commit('wipeQAPairState');
        // Use the existing method to add each fetched and augmented QA pair to state
        qaPairsWithSelected.forEach(qaPair => commit('addQAPair', qaPair));
      } catch (error) {
        console.error('Error fetching QA pairs:', error);
      }
    },
    toggleQAPairSelection({ commit }, qaId) {
      commit('toggleQAPairSelection', qaId);
    },
    async saveStatistics({ commit }, { Statistics }) {
      try {
        // Convert QA pairs to the format expected by your backend
        const payload = {
          month: "01.01.2021",
          statistics_data: Statistics
        };

        const response = await fetch(`${window.location.origin}/.netlify/functions/datacenter`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: JSON.stringify({
            callname: "statistics",
            action: "POST",
            payload: { data: payload }
          })
        });

        if (!response.ok) {
          throw new Error('Failed to save QA pairs');
        }

        const responseData = await response.json();
        console.log(responseData);
        // Optionally, commit a mutation to update state with new QA pair data
        Statistics.forEach(statistic => commit('addStatistics', statistic));
        commit("fetchStatistics");
      } catch (error) {
        console.error('Error saving QA pairs:', error);
      }
    },
    async updateStatistics({ commit }, { statisticId, Statistics }) {
      try {
        console.log("Statistics", Statistics)
        console.log("statisticId", statisticId)
        const payload = { statistics_data: Statistics };
        const response = await fetch(`${window.location.origin}/.netlify/functions/datacenter`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: JSON.stringify({
            callname: `statistics/${statisticId}`,
            action: "PUT",
            payload: { data: payload }
          })
        });

        if (!response.ok) {
          throw new Error('Failed to update QA pair');
        }

        const responseData = await response.json();
        console.log(responseData);

        commit('updateStatistics', { statisticId, Statistics }); // Update local state
      } catch (error) {
        console.error('Error updating QA pair:', error);
      }
    },
    async fetchStatistics({ commit }) {
      try {
        const response = await fetch(`${window.location.origin}/.netlify/functions/datacenter`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: JSON.stringify({
            callname: `statistics`,
            action: "GET",
          })
        });

        if (!response.ok) {
          throw new Error('Failed to fetch QA pairs');
        }

        const responseData = await response.json();
        console.log("fetch trough the statistics here again", responseData);
        // set Statistics after fetching
        commit('setStatistics', responseData.data);

      } catch (error) {
        console.error('Error fetching QA pairs:', error);
      }
    },
    async callApiFunction(context, { functionName, payload }) {
      try {
        const response = await fetch(`${VUE_APP_MAIN_API_URL}/api/v0/${functionName}`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Failed to call API:', error);
        return null;
      }
    },
    toggleToolActivation({ commit }, payload) {
      commit('toggleToolActivation', payload);
    }
  },
  getters: {
    isSidebarOpen: state => state.isSidebarOpen,
    notifications: (state) => state.notifications,

  },
  plugins: [
    createPersistedState(),
    store => {
      store.subscribe((mutation, state) => {
        if (mutation.type === 'setAuthStatus') {
          localStorage.setItem('isAuthenticated', state.isAuthenticated);
        }
      });

      const isAuthenticated = localStorage.getItem('isAuthenticated');
      if (isAuthenticated !== null) {
        store.commit('setAuthStatus', JSON.parse(isAuthenticated));
      }
    }
  ]
});
