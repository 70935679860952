import { createRouter, createWebHistory } from "vue-router";
import { authService } from '@/auth/authService'; // Adjust the path as necessary
import Chat from "../views/Chat.vue";
import Training from "../views/Training.vue";
import Dashboard from "../views/Dashboard.vue";
import ExpertsTable from "../views/ExpertsTable.vue";
import RTL from "../views/Rtl.vue";
import Apps from "../views/Apps.vue";
import Profile from "../views/Profile.vue";
import SignIn from "../views/SignIn.vue";
import News from "../views/News.vue";
import Home from "../views/Home.vue";
import store from '../store'; // Assuming you have Vuex store setup


const routes = [
  {
    path: "/",
    name: "/",
    meta: { requiresAuth: false },
    component: Home,
    redirect: "/news",
  },
  {
    path: "/home",
    name: "/home",
    meta: { requiresAuth: false },
    component: Home,
  },
  {
    path: "/datacenter-token",
    name: "/datacenter-token",
    meta: { requiresAuth: false },
    beforeEnter() {
      const redirectUrl = `${process.env.VUE_APP_DATACENTER_URL}/api/connect/auth0?redirect_uri=${process.env.VUE_APP_BASE_URL}/api-token`;
      window.location.replace(redirectUrl);
    }
  },
  {
    path: "/api-token",
    name: "/api-token",
    meta: { requiresAuth: false },
    beforeEnter() {
      const redirectUrl = `${process.env.VUE_APP_MAIN_API_URL}/api/connect/auth0?redirect_uri=${process.env.VUE_APP_BASE_URL}/news`;
      window.location.replace(redirectUrl); // Redirect to obtain token
    }
  },
  {
    path: "/news",
    name: "News",
    meta: { requiresAuth: false },
    component: News,
  },
  {
    path: "/chat",
    name: "Chat",
    meta: { requiresAuth: true },
    component: Chat,
  },
  {
    path: "/training",
    name: "Training",
    meta: { requiresAuth: true },
    component: Training,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: { requiresAuth: true },
    component: Dashboard,
  },
  {
    path: "/experts",
    name: "Experts",
    meta: { requiresAuth: true },
    component: ExpertsTable,
  },
  {
    path: "/billing",
    name: "Billing",
    meta: { requiresAuth: true },
    beforeEnter() {
      window.location.replace("https://pay.b-bot.ch/p/login/3cs29k6SSeIS7bacMM")
    }
  },
  {
    path: "/rtl-page",
    name: "RTL",
    meta: { requiresAuth: true },
    component: RTL,
  },
  {
    path: "/apps",
    name: "Apps",
    meta: { requiresAuth: true },
    component: Apps,
  },
  {
    path: "/profile",
    name: "Profile",
    meta: { requiresAuth: true },
    component: Profile,
  },
  {
    path: "/sign-in",
    name: "SignIn",
    meta: { requiresAuth: false },
    component: SignIn,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach(async (to, from, next) => {
  await store.dispatch('setIsBackgroundLoading', true); // Show loading screen

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  // Initialize authService client if it hasn't been already
  await authService.initClient();
  await store.dispatch('setIsBackgroundLoading', false);
  // Check for unsaved changes before proceeding with any navigation

  // Check if the route requires authentication
  if (requiresAuth) {
    const isAuthenticated = store.state.isAuthenticated;
    console.log("isAuthenticated: ",isAuthenticated);
    if (isAuthenticated) {
      next(); // Proceed if authenticated
    } else {

      next('/sign-in'); // Redirect to sign-in page if not authenticated
    }
  } else {
    next(); // Always allow if the route does not require authentication
  }
});

router.afterEach(() => {
  store.dispatch('setIsBackgroundLoading', false); // Hide loading screen after navigation is complete
});

export default router;
