<template>
  <VueFinalModal
      v-model="isModalOpen"
      class="flex justify-center items-center confirm-modal overflow-scroll"
      content-class="flex flex-col max-w-xl mx-auto p-4 dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-2 confirm-modal-content"
      overlay-transition="vfm-fade"
      content-transition="vfm-fade"
  >
    <template #header>
      <h3>Select an Ability</h3>
      <button @click="closeModal" class="close-btn">Close</button>

    </template>

    <template #default>

      <div class="card-header bg-gradient-primary d-flex justify-content-between shadow-primary card-header-top">
        <h5 class="modal-title text-white">Select an Ability</h5>
      </div>
      <div class="container">
        <div class="row justify-content-center">
          <ul class="conversations-list list-unstyled w-100 d-flex flex-wrap">
            <li v-for="(ability, index) in selectedExpert.attributes.abilities.data" :key="index"
                :class="{
                  'col-12': true,
                  'col-md-3': true,
                  'mb-4': true,
                  'p-2': true,
                  'd-flex': true,
                  'align-items-stretch': true,
                  'active': isSelectedAbility(ability)
            }">
              <div class="card">
                <div class="card-body d-flex flex-column">
                  <div class="pb-0 card-header">
                    <p class="text-sm">{{ ability.attributes.text }}</p>
                  </div>
                  <div class="mt-auto">
                    <material-button
                        @click="selectAbility(ability)"
                        class="btn-sm"
                        variant="gradient"
                        color="primary"
                        fullWidth
                        :disabled="isSelectedAbility(ability)"
                    >
                      Use Ability
                    </material-button>
                  </div>
                </div>
              </div>
            </li>
          </ul>

        </div>
        <button @click="closeModal" class="close-btn btn">
          <i class="material-icons-round opacity-10 fs-5">close</i>
        </button>
      </div>
    </template>

    <template #footer>
      <button @click="closeModal" class="close-btn">Close</button>
    </template>
  </VueFinalModal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'
import { mapState, mapMutations } from 'vuex'
import MaterialButton from "@/components/MaterialButton.vue";

export default {
  components: {
    MaterialButton,
    VueFinalModal,
  },
  props: {
    modelValue: Boolean,
  },
  data() {
    return {
      searchQuery: '',
    };
  },
  computed: {
    ...mapState(['selectedExpert', 'selectedAbility']),
    isModalOpen: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    ...mapMutations(['setSelectedAbility']),
    selectAbility(ability) {
      console.log("before selection:", this.selectedAbility)
      this.setSelectedAbility(ability);
      console.log("after selection:", this.selectedAbility)

      this.isModalOpen = false; // Close the modal
    },
    isSelectedAbility(ability) {
      return this.selectedAbility !== null && this.selectedAbility.id === ability.id;
    },
    closeModal() {
      this.isModalOpen = false;
    },
  },
};
</script>

<style scoped>
.search-box {
  margin-bottom: 1rem;
}

.search-input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
}

.conversations-list {
  list-style: none;
  padding: 0;
}

.conversation-btn {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: left;
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1.7rem;
  margin-right: 1rem;
  color: white;
}

.confirm-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000 !important;
  border-bottom-left-radius: .75rem;
  border-bottom-right-radius: .75rem;
}


.confirm-modal >>> .confirm-modal-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: #fff;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.confirm-modal-content > * + *{
  margin: 0.5rem 0;
}
.confirm-modal-content h1 {
  font-size: 1.375rem;
}

.dark .confirm-modal-content {
  background: #000;
}
.active .card{
  border: 3px solid green;
}

.card-header{
  border-radius: .75rem .75rem .75rem .75rem !important;
}

</style>
