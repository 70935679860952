<template>
  <div class="container">
    <div v-if="isLoading" class="loading-overlay">
      <vue3-lottie ref="lottie"
                   :animationData="animationData"
                   height="60vh"
                   width="100%"
      />
    </div>
    <div class="row">
      <h6 class="text-secondary mb-2">App Connections</h6>

      <!-- Displaying app connections -->
      <div class="row mb-5" v-if="appKeys.length > 0">
        <div class="col-md-3" v-for="appKey in appKeys" :key="appKey">
          <div class="card app-item shadow-sm">
            <img :src="getAppDetails[appKey]?.image" :alt="getAppDetails[appKey]?.name" class="card-img-top app-icon"/>
            <div class="card-body">
              <h5 class="card-title text-center">{{ getAppDetails[appKey]?.name }}</h5>
              <div v-if="apps[appKey]?.actions">
                <div v-for="(action, actionKey) in apps[appKey].actions" :key="actionKey">
                  <button @click="performAction(appKey, actionKey)" class="btn btn-primary w-100 m-0">
                    {{ action.name }}
                  </button>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-around">
              <button @click="deleteApp(appKey)" class="btn btn-danger w-100 m-0">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Message when no app connections found -->
      <p v-else>No app connections found. You can add some in the Apps Section</p>

      <div class="row d-flex justify-content-center align-items-center mb-3 alert alert-warning p-0 d-none">
        <div class="col-12 col-md-6">
          <div class="alert-icon">
            <i class="fas fa-exclamation-triangle"></i>
          </div>
          <img
              class="w-75 me-3 mb-0"
              src="@/assets/img/illustrations/link-dynamic-gradient.png"
              alt="logo"
          />
        </div>
        <div class="col-12 col-md-6">
          <h1 style="color: white">App Connections</h1>

          <h2 style="color: white">This feature will be ready soon!</h2>
          <p>
            You will soon be able to choose from a variety of Apps to chat and train with. Stay tuned!
          </p>

        </div>
        <div class="d-flex justify-content-around p-0 d-none">
          <button class="btn btn-primary w-100 m-0" @click="toNext()">
            Next Step
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import tavilyIcon from '@/assets/icons/tavily.png';
import wolframIcon from '@/assets/icons/wolfram.png';
import documentRetrieverIcon from '@/assets/icons/document_retriever.png';
import notionIcon from '@/assets/icons/notion.png';
import loadingChat from "@/assets/img/illustrations/loadingChat.json";
import upstashIcon from "@/assets/icons/upstash-redis-private.png";
import redisIcon from "@/assets/icons/upstash-redis-private.png";

export default {
  props: {
    expertApps: {
      type: [String, Object], // Allow expertApps to be either a String or Object
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      animationData: loadingChat,
    };
  },
  computed: {
    ...mapState(['apps', 'selectedExpert', 'user']),
    parsedApps() {
      if (typeof this.expertApps === 'string') {
        try {
          return JSON.parse(this.expertApps);
        } catch (error) {
          console.error('Error parsing expertApps:', error);
          return {}; // Return an empty object if parsing fails
        }
      } else {
        // If expertApps is already an object (or object-like structure), return it directly
        return this.expertApps;
      }
    },
    appKeys() {
      return Object.keys(this.parsedApps);
    },
    appImages() {
      return {
        tavily_search: tavilyIcon,
        wolfram_alpha: wolframIcon,
        document_retriever: documentRetrieverIcon,
        notion_connector: notionIcon,
        upstash_redis_private: upstashIcon,
        redis_private: redisIcon,
      };
    },
    getAppDetails() {
      // Map expertApps to app names and images
      const appDetails = {};
      for (const [key, value] of Object.entries(this.expertApps)) {
        console.log('Key:', key);
        console.log('Value:', value);
        appDetails[key] = {
          name: this.apps[key]?.name || value.name,
          image: this.appImages[key] || require('@/assets/img/illustrations/link-dynamic-gradient.png') // Provide a default image
        };
      }
      return appDetails;
    }
  },
  mounted() {
    console.log('Expert Apps:', this.expertApps);
    console.log('Parsed Expert Apps:', this.parsedApps);
    console.log('App Keys:', this.appKeys);
    console.log('User in User Apps:', this.user);
  },
  methods: {
    ...mapActions(['callApiFunction', 'deleteUserMetadataApp']),
    deleteApp(appKey) {
      console.log('Deleting app:', appKey);
      if (confirm('Are you sure you want to delete this app?')) {
        this.deleteUserMetadataApp(appKey)
            .then(() => {
              console.log('Metadata updated successfully:', appKey);
            })
            .catch((error) => {
              console.error('Error updating metadata:', error);
            });
      }
    },
    toNext() {
      this.$emit('to-next')
    },
    performAction(appKey, action) {
      this.isLoading = true;
      switch(appKey) {
        case 'notion_connector':
          if (action === 'sync') {
            this.syncNotionConnector();
          }
          break;
          // Add more cases for other apps and their respective actions
        default:
          console.log(`Action ${action} for app ${appKey} is not defined.`);
      }
    },
    async syncNotionConnector() {
      console.log('Syncing Notion Connector...');
      console.log('This USER: ',this.user.sub);


      const payload = {
        id: this.user.sub,
        apps: this.user.hub_user_metadata.apps,
        private: true
      };
      console.log(payload);

      try {
        const response = await this.callApiFunction({ functionName: 'connect_notion', payload });
        console.log('Notion sync response:', response);
        // Handle the response as needed
      } catch (error) {
        console.error('Failed to sync Notion Connector:', error);
      }
      this.isLoading = false;

    }
  }
};
</script>

<style scoped>
.app-item {
  margin-bottom: 20px;
}
.app-icon {
  width: 100%;
  height: auto;
  object-fit: contain;
  padding: 20px;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2em;
  z-index: 9999;
}
</style>
