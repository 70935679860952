<template>
  <div
      :class="'chat-message d-flex align-items-start position-relative' +  'is-selected ' + isChecked + ' background_' + sender"
  >


    <!-- Message Content -->
    <div class="message-content ms-2">
      <div class="message-header">
        <div class="mr-5" v-if="isSelectionMode">
          <material-switch @change="selectMessage" label-class="mb-0 text-body text-truncate w-80" type="checkbox" v-model="isChecked" :id="id" :name="id"></material-switch>
        </div>
        <MaterialAvatar
            v-else
            :img="profilePictureUrl"
            alt="Profile Picture"
            size="small"
            shadow="regular"
            :circular="true"
            borderRadius="50%"
        />
        <span class="sender">{{ sender }}</span>
        <span class="timestamp">{{ formattedTimestamp }}</span>
        <i class="material-icons-round fs-5" v-if="hasThoughts()">psychology</i>
      </div>
      <v-md-preview class="message-text" v-if="sender === 'assistant'" :text="text"></v-md-preview>
      <p class="message-text" v-else>{{text}}</p>
    </div>
    <!-- Dropdown Toggle -->
    <button class="show-more" type="button" data-bs-toggle="dropdown" aria-expanded="false" :id="`dropdownMenuButton-${id}`">
      <i class="material-icons-round opacity-10 fs-5">more_vert</i>
    </button>
    <!-- Dropdown Menu -->
    <ul class="dropdown-menu" :aria-labelledby="`dropdownMenuButton-${id}`">
      <li><a class="dropdown-item" href="#" @click.prevent="copyContent">Copy</a></li>
      <li><a class="dropdown-item" href="#" @click.prevent="emitStartQAMarking">Start QA Marking</a></li>
      <li><a class="dropdown-item" href="#" @click.prevent="openThoughtsModal" v-if="hasThoughts()">Thoughts</a></li>

    </ul>
  </div>
  <VueFinalModal
      v-model="isThoughtsModalOpen"
      class="flex justify-center items-center confirm-modal overflow-scroll"
      content-class="flex flex-col max-w-xl mx-auto p-4 dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-2 confirm-modal-content"
      overlay-transition="vfm-fade"
      content-transition="vfm-fade"
  >
    <!-- Modal Content -->
    <template #default>
      <div class="card-header bg-gradient-primary d-flex justify-content-between shadow-primary card-header-top">
        <h5 class="modal-title text-white">Thoughts</h5>
      </div>
      <div class="accordion" id="thoughtsAccordion">
        <div v-for="(thought, index) in thoughts" :key="index" class="accordion-item">
          <h2 class="accordion-header" :id="'heading' + index">
            <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                :data-bs-target="'#collapse' + index"
                :aria-expanded="index === 0 ? 'true' : 'false'"
                :aria-controls="'collapse' + index"
            >
              Thought {{ index + 1 }}
            </button>
          </h2>
          <div
              :id="'collapse' + index"
              class="accordion-collapse collapse"
              :class="{ show: index === 0 }"
              :aria-labelledby="'heading' + index"
              data-bs-parent="#thoughtsAccordion"
          >
            <div class="accordion-body">
              <!-- Check if it's a Consultation -->
              <div v-if="thought && thought[0] && thought[0].type && thought[0].tool.startsWith('Consultation_')">
                <!-- Display Invocation -->

                <div class="mb-2 mt-2" v-html="'Invoking: `' + thought[0].tool"></div>

                <MaterialAvatar
                    :img="profilePictureUrl"
                    alt="Profile Picture"
                    size="small"
                    shadow="regular"
                    :circular="true"
                    borderRadius="50%"
                />
                <!-- Display Input -->
                <div v-if="thought[1].input">
                  <h5>Input:</h5>
                  <p>{{ thought[1].input }}</p>
                </div>

                <!-- Display Output -->
                <div v-if="thought[1].output">
                  <h5>Output:</h5>
                  <div v-html="thought[1].output"></div>
                </div>

                <!-- Display Intermediate Steps -->
                <div v-if="thought[1].intermediate_steps && thought[1].intermediate_steps.length > 0">
                  <h5>Intermediate Steps:</h5>
                  <ul>
                    <li v-for="(step, stepIndex) in thought[1].intermediate_steps" :key="stepIndex">
                      <p>{{ step }}</p>
                    </li>
                  </ul>
                </div>
                <div v-else>
                  <p>No intermediate steps available.</p>
                </div>
              </div>

              <!-- General Content Rendering -->
              <div v-else>
                <div v-html="thought[0].log"></div>
                <div v-if="thought[1] && Array.isArray(thought[1])">
                  <div>
                    <div v-for="(item, itemIndex) in thought[1]" :key="itemIndex">
                      <div v-for="(value, key) in item" :key="key">
                        <div v-if="key === 'url'">
                          <a :href="value" target="_blank" class="btn btn-primary" role="button">{{ value }}</a>
                        </div>
                        <div v-else-if="key === 'content'">
                          <p>{{ value }}</p>
                        </div>
                        <div v-else>
                          <!-- Handle other attributes if needed -->
                          <strong>{{ key }}:</strong> {{ value }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  {{ thought[1] }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button @click="closeThoughtsModal" class="close-btn btn">
        <i class="material-icons-round opacity-10 fs-5">close</i>
      </button>
    </template>

  </VueFinalModal>
</template>

<script>
import MaterialAvatar from "@/components/MaterialAvatar.vue";
import {VueFinalModal} from "vue-final-modal";
import MaterialSwitch from "@/components/MaterialSwitch.vue";

export default {
  name: 'ChatMessage',
  components: {
    MaterialSwitch,
    VueFinalModal,
    MaterialAvatar,
  },
  props: {
    sender: String,
    text: String,
    timestamp: String,
    thoughts: Array,
    profilePictureUrl: String,
    id: String,
    isSelected: Boolean,
    isSelectionMode: Boolean, // Add this line

  },
  data() {
    return {
      isChecked: this.isSelected,
      isThoughtsModalOpen: false,
    };
  },
  computed: {
    messageObject() {
      return {
        id: this.id,
        sender: this.sender,
        text: this.text,
        timestamp: this.timestamp,
        thoughts: this.thoughts,
        profilePictureUrl: this.profilePictureUrl,
        isSelected: this.isSelected
      };
  },
    formattedTimestamp() {
      // Assuming timestamp prop is a Date string, format it here
      // This is just a placeholder. Implement formatting as needed
      return this.timestamp;
    }
  },
  methods: {
    copyContent() {
      navigator.clipboard.writeText(this.text).then(() => {
        alert('Content copied to clipboard');
      }, (err) => {
        console.error('Could not copy text: ', err);
      });
    },
    emitStartQAMarking() {
      // This will inform the parent component to enter selection mode and mark this message
      this.$emit('start-qa-marking', this.messageObject);
    },
    toggleSelection() {
      this.isChecked = !this.isChecked;
      this.selectMessage();
    },
    selectMessage() {
      console.log("check is selected: ", this.isChecked);
      this.$emit('select-message', this.messageObject);
    },
    handleClick() {
      if (this.isSelectionMode) {
        // In selection mode, toggle the selection
        this.toggleSelection();
      } else {
        // Otherwise, perform other click actions, if any
      }
    },
    hasThoughts() {
      // Check if the message has thoughts
      // Replace `this.message` with the actual message data
      console.log('Thoughts:', this.thoughts);
      return this.thoughts && this.thoughts.length > 0;
    },
    openThoughtsModal() {
      this.isThoughtsModalOpen = true;
    },
    closeThoughtsModal() {
      this.isThoughtsModalOpen = false;
    }
  },
  watch: {
    isSelected(newVal) {
      this.isChecked = newVal;
    },
  },
};
</script>

<style scoped>
.chat-message {
  display: flex;
  align-items: flex-start; /* Align items at the start for messages */
  margin-bottom: 30px;
  border-radius: 1rem;
  padding-bottom: 10px;
  padding-top: 5px;
}

.chat-message:hover {
  background: #f5f5f5;
}

.background_user {
  background: #f1f1f1;
  text-align: right;
}

.dark-version .background_user {
  background: #252525;
  color: white;
}

.dark-version .chat-message:hover {
  background: #606162;
  color: white;
}

.message-content {
  width: 100%;
  border-radius: 8px;
  margin-left: 10px /* Space between avatar and message content */

}

.message-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.sender {
  font-weight: bold;
}

.timestamp {
  color: #999;
  font-size: 0.8rem;
}

.message-text {
  word-break: break-word;
}
.avatar {
  width: 30px;
  height: 30px;
}
.show-more{
  background: transparent;
  outline: none;
  border: none;
  z-index: 1;
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1.7rem;
  margin-right: 1rem;
  color: white;
}

.confirm-modal {
  overflow-y: scroll !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000 !important;
  border-bottom-left-radius: .75rem;
  border-bottom-right-radius: .75rem;
}


.confirm-modal >>> .confirm-modal-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: #fff;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}


.confirm-modal-content h1 {
  font-size: 1.375rem;
}
</style>
