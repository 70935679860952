<template>
  <div class="container mt-5">
    <div class="d-flex justify-content-center align-items-center mb-3">
      <img
          class="w-25 me-3 mb-0 backdrop"
          src="@/assets/img/illustrations/link-dynamic-gradient.png"
          alt="logo"
      />
      <h2>Appstore</h2>
    </div>

    <div class="row">
      <div class="col-md-3 mb-3">
        <h2>Databases</h2>
        <div v-for="app in database" :key="app.id" class="card mb-3 app-card">
          <div class="card-body">
            <img
                class="w-100 me-3 mb-0"
                src="@/assets/img/logos/upstash_redis.png"
                alt="logo"
            />
            <h5 class="card-title">{{ app.name }}</h5>
            <p class="card-text">{{ app.description }}</p>
          </div>
          <div class="d-flex justify-content-around">
            <button
                v-if="app.connectable === true && app.connected === false"
                class="btn btn-primary mb-0 w-100"
                @click="selectApp(app)"
            >Connect</button>
            <button
                v-if="app.connectable === true && app.connected === true"
                class="btn btn-primary mb-0 w-100"
                @click="selectApp(app)"
            >Config</button>
            <a
                v-if="app.connectable === false"
                class="btn btn-primary mb-0 w-100"
                :href="app.href"
            >More Information</a>
          </div>
        </div>
      </div>

      <div class="col-md-3 mb-3">
        <h2>Connectors</h2>
        <div v-for="app in connectors" :key="app.id" class="card mb-3 app-card">
          <div class="card-body">
            <img class="w-100 me-3 mb-0" :src="app.logo" alt="logo" />
            <h5 class="card-title">{{ app.name }}</h5>
            <p class="card-text">{{ app.description }}</p>
          </div>
          <div class="d-flex justify-content-around">

            <button
                v-if="app.connectable === true && app.connected === false"
                class="btn btn-primary mb-0 w-100"
                @click="selectApp(app)"
            >Connect</button>
            <button
                v-if="app.connectable === true && app.connected === true"
                class="btn btn-primary mb-0 w-100"
                @click="selectApp(app)"
            >Config</button>
            <a
                v-if="app.connectable === false"
                class="btn btn-primary mb-0 w-100"
                :href="app.href"
            >More Information</a>
          </div>
        </div>
      </div>

      <div class="col-md-3 mb-3">
        <h2>Tools</h2>
        <div v-for="app in tools" :key="app.id" class="card mb-3 app-card">
          <div class="card-body">
            <img class="w-100 me-3 mb-0" :src="app.logo" alt="logo" />
            <h5 class="card-title">{{ app.name }}</h5>
            <p class="card-text">{{ app.description }}</p>
          </div>
          <div class="d-flex justify-content-around">
            <button
                v-if="app.connectable === true && app.connected === false"
                class="btn btn-primary mb-0 w-100"
                @click="selectApp(app)"
            >Connect</button>
            <button
                v-if="app.connectable === true && app.connected === true"
                class="btn btn-primary mb-0 w-100"
                @click="selectApp(app)"
            >Config</button>
            <a
                v-if="app.connectable === false"
                class="btn btn-primary mb-0 w-100"
                :href="app.href"
            >More Information</a>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal for selected app -->
    <VueFinalModal
        v-model="showModal"
        classes="modal-container"
        class="modal-container overflow-scroll"
        content-class="modal-content"
        :hide-overlay="true"
         overlay-transition="vfm-fade"
         content-transition="vfm-fade"
    >
      <div v-if="selectedApp" class="mt-4 mb-4 card p-3">
        <div class="card-header bg-gradient-primary d-flex justify-content-between shadow-primary card-header-top p-3">
          <h5 class="modal-title text-white">Settings for {{ selectedApp.name }}</h5>

          <button type="button" class="close-btn btn text-white" @click="closeModal">
            <i class="material-icons-round opacity-10 fs-5">close</i>
          </button>
        </div>
        <DynamicForm
            :settings="selectedApp.settings"
            :descriptions="selectedApp.descriptions"
            :validations="selectedApp.validations"
            @update="updateFormData"
            @form-valid="handleFormValid"
        />
        <button @click="connectApp" :disabled="!isFormValid" class="btn btn-success mt-2">Save Settings</button>
      </div>
    </VueFinalModal>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import DynamicForm from "@/views/components/DynamicForm.vue";
import {VueFinalModal} from "vue-final-modal";
const { VUE_APP_DATACENTER_URL } = process.env;

export default {
  data() {
    return {
      selectedApp: null,
      showModal: false,
      formData: {},
      isFormValid: false,
      database: [
        {
          id: 1,
          identifier: 'upstash_redis_public',
          name: 'Upstash Redis Public',
          description: 'The Public Vector Database for B-Bot',
          connected: false,
          database: true,
          database_type: 'upstash',
          connectable: false,
          href: 'https://upstash.com',
          settings: {
            private: true,
            url: 'https://api.upstash.com',
            apiKey: '',
          },
          descriptions: {
            private: '',
            url: 'API URL for Upstash',
            apiKey: 'API Key for Upstash',
          },
          validations: {
          },
        },
        {
          id: 2,
          identifier: 'upstash_redis_private',
          name: 'Own Upstash Redis',
          description: 'Choose your own Upstash Redis Database',
          connected: false,
          connectable: true,
          database: true,
          database_type: 'upstash',
          href: 'https://upstash.com',
          settings: {
            private: true,
            redis_rest_url: 'https://something-44230.upstash.io',
            redis_rest_token: 'GAAIncDE2ODFiMDA0Mjc0NmU0NmIzOTU3MDcyZmNl',
            vector_url: 'https://api.upstash.com',
            vector_token: 'GAAIncDE'
          },
          descriptions: {
            private: '',
            redis_rest_url: 'Upstash Redis Rest URL, can be found in the dashboard',
            redis_rest_token: 'Upstash Redis Rest TOKEN, can be found in the dashboard',
            vector_url: 'Upstash Vector URL, can be found in the dashboard',
            vector_token: 'Upstash Vector TOKEN, can be found in the dashboard',
          },
          validations: {
            redis_rest_url: {
              required: true,
              type: 'string',
              pattern: 'https://.*',
              custom: (value) => {
                if (!value.startsWith('https')) {
                  return 'URL must start with https';
                }
                return true;
              }
            },
            vector_url: {
              required: true,
              type: 'string',
              pattern: 'https://.*',
              custom: (value) => {
                if (!value.startsWith('https')) {
                  return 'URL must start with https';
                }
                return true;
              }
            },
            vector_token: {
              required: true,
              type: 'string',
              minLength: 32,
              maxLength: 64,
              custom: (value) => {
                if (!/^[a-zA-Z0-9]+$/.test(value)) {
                  return 'API Key must be alphanumeric';
                }
                return true;
              }
            },
            redis_rest_token: {
              required: true,
              type: 'string',
              minLength: 32,
              maxLength: 64,
              custom: (value) => {
                if (!/^[a-zA-Z0-9]+$/.test(value)) {
                  return 'API Key must be alphanumeric';
                }
                return true;
              }
            },
          },
        },
        {
          id: 3,
          identifier: 'redis_private',
          name: 'Own Redis',
          description: 'Choose your own Redis Database',
          connected: false,
          connectable: true,
          database: true,
          database_type: 'redis',
          href: 'https://upstash.io',
          settings: {
            private: true,
            redis_rest_url: 'redis://default:AazGU0NmIzOTU3MDcyZmNlOWVhZTdkZnAxNDQyMzA@example-koala-44230.redis.io:6379',
          },
          descriptions: {
            private: '',
            redis_rest_url: 'Redis URL of your own private Redis Database',
          },
          validations: {
            redis_rest_url: {
              required: true,
              type: 'string',
              pattern: 'redis://.*',
              custom: (value) => {
                if (!value.startsWith('redis://')) {
                  return 'URL must start with redis://';
                }
                return true;
              }
            },
          },
        },
      ],
      connectors: [
        {
          id: 1,
          identifier: 'notion_connector',
          name: 'Notion Connector',
          description: 'Synchronize your database with B-Bot. | coming soon',
          logo: 'https://cdn.freelogovectors.net/wp-content/uploads/2023/09/notionlogo-freelogovectors.net_.png',
          connected: false,
          connectable: true,
          href: 'https://upstash.com',
          settings: {
            private: true,
            db: '',
            secret: '',
          },
          descriptions: {
            private: '',
            db: 'Database ID of Notion Page of Folder',
            secret: 'Secret key for the Notion API (you must create our own App in the Notion App)',
          },
        },
      ],
      tools: [
        {
          id: 1,
          identifier: 'tavily_search',
          name: 'Tavily Search',
          description: 'GPT Researcher that researches the Internet for you',
          logo: 'https://docs.tavily.com/img/tavily.png',
          connected: false,
          connectable: false,
          href: 'https://upstash.com',
          settings: {
            private: true,
            url: 'https://api.upstash.com',
            apiKey: '',
          },
          descriptions: {
            private: '',
            url: 'Url for Tavily Search API',
            apiKey: 'API Key for Tavily Search API',
          },
          validations: {
            url: {
              required: true,
              type: 'string',
              pattern: 'https://.*',
              custom: (value) => {
                if (!value.startsWith('https')) {
                  return 'URL must start with https';
                }
                return true;
              }
            }
          },
        },
        {
          id: 2,
          identifier: 'wolfram_alpha',
          name: 'Wolfram Alpha',
          description: 'Calculates and answers scientific questions.',
          logo: 'https://w7.pngwing.com/pngs/767/137/png-transparent-wolfram-alpha-logo-search-engines.png',
          connected: false,
          connectable: false,
          href: 'https://upstash.com',
        },
        {
          id: 3,
          identifier: 'openai_assistant',
          name: 'OpenAI Assistant',
          description: 'An Assistant for you B-Bot to help you with your tasks',
          logo: 'https://media.licdn.com/dms/image/D5612AQEzwj7EvpcXHQ/article-cover_image-shrink_720_1280/0/1700332696859?e=2147483647&v=beta&t=O_m9znUFQLgn06PXf7Ck6UgXNcztGq5-CpDwHTdgzLU',
          connected: false,
          connectable: true,
          href: 'https://platform.openai.com/docs/assistants/overview',
          settings: {
            private: true,
            tool_type: 'openai_asst',
            name: 'Name of the Assistant',
            description: 'Description of the Assistant',
            assistant_id: 'asst_1234567890',
            apiKey: 'sk-1234567890',
          },
          descriptions: {
            private: '',
            tool_type: 'API URL for Upstash',
            name: 'Name of the Assistant tool, e.g. Law Assistant (This is the Tool name B-Bot sees)',
            description: 'Description of the Assistant tool, e.g. Assists in Law questions knows everything about law. (This is the Tool description B-Bot sees)',
            assistant_id: 'The Assistant ID for OpenAI Assistant',
            apiKey: 'API Key of the OpenAI',
          },
        },
      ],
    };
  },
  computed:{
    ...mapState(['experts', 'selectedExpert']),
  },
  methods: {
    ...mapActions(['updateUserMetadataApp', 'fetchExperts']),
    ...mapMutations(['showEveryDisplay']),
    connectApp() {
      let key = this.selectedApp.identifier;
      const value = this.formData;

      if(this.selectedApp.identifier === 'openai_assistant'){
        key = this.selectedApp.identifier + '_' + this.formData.assistant_id;
      }

      console.log('Updating metadata:', key, value);

      // Include database_type in the payload
      const payload = {
        ...value,
        database_type: this.selectedApp.database_type,
      };

      if (this.formData?.private === true) {
        this.$store.dispatch('updateUserMetadataApp', { key, value: payload })
            .then(() => {
              console.log('Metadata updated successfully:', key, payload);
            })
            .catch((error) => {
              console.error('Error updating metadata:', error);
            });
      } else {
        this.handleSubmit(key, payload);
      }
      this.selectedApp = null;
      this.showModal = false;
    },
    closeModal() {
      this.showModal = false;
    },
    handleFormValid(isValid) {
      this.isFormValid = isValid;
    },
    updateFormData(newFormData) {
      this.formData = {};
      this.formData = newFormData;
    },
    selectApp(app) {
      this.selectedApp = app;
      this.formData = app.settings;
      this.showModal = true;
    },
    getCookie(name) {
      let value = `; ${document.cookie}`;
      let parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    },
    async handleSubmit(key, payload) {
      console.log("handleSubmit");
      const url = this.selectedExpert.id
          ? `${VUE_APP_DATACENTER_URL}/api/experts/${this.selectedExpert.id}` // PUT to update existing expert
          : `${VUE_APP_DATACENTER_URL}/api/experts`; // POST to create new expert

      const method = this.selectedExpert.id ? 'PUT' : 'POST';

      const accessToken = this.getCookie('datacenterAccessToken');

      try {
        console.log("this.expertData.name: " ,this.selectedExpert.attributes.name)
        const appIdentifier = key;
        const currentSettings = this.selectedExpert.attributes.apps || {};
        const updatedSettings = {
          ...currentSettings,
          [appIdentifier]: payload,
        };
        const response = await fetch(url, {
          method: method,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            data: {
              apps: updatedSettings,
            },
          }),
        });

        if (!response.ok) {
          throw new Error(`Failed to ${this.selectedExpert.id ? 'update' : 'create'} expert: ${response.statusText}`);
        }

        const responseData = await response.json();

        console.log(responseData);
        setTimeout(() => {
          this.fetchExperts();
          console.log("fetchExperts");
        }, 1000);
        this.showModal = false;

      } catch (error) {
        console.error('Error submitting expert data:', error);
        // Handle error, e.g., showing error message to the user
      }
      this.showModal = false;
    },
    sync(app) {
      console.log('Syncing:', app);
    },
  },
  mounted() {
    this.showEveryDisplay();
  },
  components:{
    VueFinalModal,
    DynamicForm
  }
};
</script>

<style scoped>
.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content-card {
  width: 90%;
  max-width: 600px;
  margin: auto;
  overflow: hidden; /* Prevents content from overflowing the modal */
}

.modal-fullscreen {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.modal-scrollable-content {
  padding: 15px;
  overflow-y: auto; /* Enables scrolling for the content area */
  overflow-x: hidden;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.card-header-top{
  border-radius: 0 0 0.75rem 0.75rem !important;
}

.card-body, .card-footer {
  padding: 1rem;
}

.btn-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
}

.mr-button{
  margin-right: 1rem;
}

.business-card {
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  padding: 20px;
}

.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-picture {
  margin-right: 15px;
  border-radius: 50%;
}

.card-title {
  margin: 0;
  color: #333333;
  font-weight: bold;
}



.form-control::placeholder {
  color: #999999;
}

.navigation-buttons-container {
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.navigation-buttons {
  pointer-events: auto;
  width: 100%;
  background: white;
}

/* Slide-fade transition for entering and leaving */
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.slide-fade-enter, .slide-fade-leave-to /* leave-to for <= Vue 2.1.8 */ {
  transform: translateX(-100%);
  opacity: 0;
}

/* Backward transition */
.slide-fade-reverse-enter-active, .slide-fade-reverse-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.slide-fade-reverse-enter, .slide-fade-reverse-leave-to /* leave-to for Vue 2.1.8 and below */ {
  transform: translateX(100%);
  opacity: 0;
}
.icon-steps{
  position: absolute;
  top: 0.7rem;
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1.7rem;
  margin-right: 1rem;
  color: white;
}

.confirm-modal {
  overflow-y: scroll !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000 !important;
  border-bottom-left-radius: .75rem;
  border-bottom-right-radius: .75rem;
}


.confirm-modal >>> .confirm-modal-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: #fff;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}


.confirm-modal-content h1 {
  font-size: 1.375rem;
}

.dark .confirm-modal-content {
  background: #000;
}
.active .card{
  border: 3px solid green;
}
.card-header{
  padding: .5rem 1rem;
  border-radius: .75rem .75rem .75rem .75rem !important;
}

.steps-indicator{
  margin-bottom: 2rem;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2em;
  z-index: 9999;
}

.dark-version .vfm--overlay {
  background: black;
}

.dark-version .vfm--content {
  background: black;
}
.app-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-height: 300px; /* Set a maximum height for the cards */
  box-sizing: border-box;
  margin-bottom: 20px; /* Adds space between cards */
}

.card-body {
  flex-grow: 1;
  overflow: hidden; /* Prevents content overflow */
}

.card-text {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis; /* Adds ellipsis if text overflows */
}

.card img {
  max-height: 100px; /* Restricts the image size */
  object-fit: contain;
  margin-bottom: 10px;
}

.card-title {
  font-size: 1.25rem;
  margin-top: 1rem;
  font-weight: bold;
}

.btn {
  margin-top: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .app-card {
    max-height: none; /* Remove max height on smaller screens */
    height: auto; /* Allow cards to adjust their height based on content */
  }

  .card img {
    max-height: 80px; /* Slightly smaller images on mobile */
  }

  .card-title {
    font-size: 1rem; /* Adjust title size on mobile */
    margin-top: 1rem;

  }
}

@media (max-width: 576px) {
  .app-card {
    max-height: none;
    height: auto;
    width: 100%; /* Ensure full width for cards on very small screens */
  }

  .card img {
    max-height: 60px; /* Even smaller images on very small screens */
  }

  .card-title {
    font-size: 0.875rem; /* Further adjust title size */
    margin-top: 1rem;

  }
}
.btn {
  margin-top: 10px;
}

.backdrop{
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
}
</style>
