<template>
  <div class="container-fluid py-4">
    <div class="d-flex justify-content-center align-items-center mb-3">
      <img
          class="w-25 me-3 mb-0 backdrop"
          src="@/assets/img/illustrations/boy-dynamic-gradient.png"
          alt="logo"
      />
      <h2>Manage Experts</h2>
    </div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <button class="btn btn-primary w-100" @click="openCreateModal">Create new Expert</button>
    </div>
    <div class="row">
      <div v-if="experts.length === 0" class="container p-0 text-center">
        <p>No experts found. you should click on add new expert</p>
      </div>
      <div v-for="expert in experts" :key="expert.id" class="col-md-6 col-lg-4 mb-3">
        <div class="card shadow-sm">
          <div class="card-body text-center">
            <img :src="expert.attributes.profile_picture?.data?.attributes?.formats?.small?.url || 'https://t4.ftcdn.net/jpg/04/30/11/17/360_F_430111702_DcBX4q0VE9CZZzyMG42FzoXHdHwM7SfA.jpg'" class="rounded-circle mb-3" :alt="expert.attributes.name" style="width: 100px; height: 100px;" />
            <h5 class="card-title">{{ expert.attributes.name }}</h5>
            <h6 class="card-subtitle mb-2 text-muted">{{ expert.attributes.profession }}</h6>
            <p class="card-text">{{ expert.attributes.function_name }}</p>
            <p class="card-text">{{ expert.attributes.function_description }}</p>
            <span class="badge bg-success">Online</span>
            <p class="card-text"><small class="text-muted">{{ formatDate(expert.attributes.createdAt) }}</small></p>

          </div>
          <div class="d-flex justify-content-around">
            <button class="btn btn-danger m-0 w-50 delete-border" @click="deleteExpert(expert)">Delete</button>
            <button class="btn btn-primary m-0 w-50 edit-border" @click="openEditModal(expert)">Edit</button>
          </div>
        </div>
      </div>
    </div>
    <ExpertCreateModal ref="expertCreateModal" :initialExpert="selectedExpert" />
    <ExpertModal ref="expertModal" :isEditMode="isEditMode" :initialExpert="selectedExpert" />
  </div>
</template>


<script>
import {mapMutations, mapState} from 'vuex';
import ExpertModal from './components/ExpertModal.vue';
import ExpertCreateModal from "@/views/components/ExpertCreateModal.vue";
const { VUE_APP_DATACENTER_URL } = process.env;

export default {
  name: 'ExpertsTable',
  components: {
    ExpertCreateModal,
    ExpertModal
  },
  computed: {
    ...mapState(['experts', 'color']),
  },
  data() {
    return {
      isEditMode: false,
      selectedExpert: {},
    };
  },
  mounted() {
    this.$store.state.showNavbar = true; // Hide the navbar when the component is mounted
    this.showEveryDisplay();
  },
  methods: {
    ...mapMutations(["showEveryDisplay"]),
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    openCreateModal() {
      this.isEditMode = false;
      this.selectedExpert = null; // Reset or set default values for new expert
      this.$refs.expertCreateModal.openModal();
    },
    openEditModal(expert) {
      this.isEditMode = true;
      console.log("adding Modal expert:", expert)
      this.selectedExpert = { ...expert }; // Load the selected expert's details
      this.$refs.expertModal.openModal();
    },
    async deleteExpert(expert) {
      if (!confirm("Are you sure you want to delete this Expert?")) {
        return;
      }
      console.log("Deleting expert:", expert)
      this.$store.commit('removeExpert', expert);
      //handle the action for the server also
      try {

        const accessToken = this.getCookie('datacenterAccessToken'); // Implement getCookie to extract cookie value
        console.log("Access Token:",accessToken)
        const response = await fetch(VUE_APP_DATACENTER_URL + '/api/experts/' + expert.id, { // Adjust URL/path as necessary
          method: 'DELETE',
          headers: {
            // 'Content-Type': 'application/json', // Note: Do not set content type when using FormData; the browser sets it to 'multipart/form-data' and includes the boundary parameter automatically
            'Authorization': `Bearer ${accessToken}`,
          },
          body: {},
        });

        if (!response.ok) {
          throw new Error(`Failed to upload image: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log(responseData);
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    },
    getCookie(name) {
      let value = `; ${document.cookie}`;
      let parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    },
  },
};
</script>

<style scoped>
.card-header:first-child {
  border-radius: 0.75rem 0.75rem 0.75rem 0.75rem;
}
.delete-border{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.edit-border{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card-body {
  flex-grow: 1;
}

.d-flex {
  display: flex;
  align-items: stretch; /* Ensures that all items stretch to the same height */
}

.card img {
  height: 150px; /* Ensures images don't stretch the card */
  object-fit: contain;
}

.backdrop{
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
}
</style>