<template>
  <div class="row">
    <div class="container p-0 text-center" v-if="editMode">
      <h2>Base Model: {{ selectedExpert.attributes?.expert_llm_models?.data[0].attributes?.base_model_type }}</h2>
      <h3>Provider: {{ selectedExpert.attributes?.expert_llm_models?.data[0].attributes?.provider }}</h3>

      <div class="container d-flex justify-content-center align-items-center mb-3 alert alert-warning p-0">
        <div class="col-12 col-md-6">
          <div class="alert-icon">
            <i class="fas fa-exclamation-triangle"></i>
          </div>
          <img
              class="w-75 me-3 mb-0"
              src="@/assets/img/illustrations/link-dynamic-gradient.png"
              alt="logo"
          />
        </div>
        <div class="col-12 col-md-6">
          <h1 style="color: white">Model Migration</h1>

          <h2 style="color: white">We will soon provide you with our migration Feature</h2>
          <p>
            After your first creation you might want to migrate your model to a never version or a different model. We will provide you with a feature to do so.
          </p>

        </div>
      </div>    </div>
    <div v-else class="col-md-3 model-container" v-for="model in models" :key="model.src">
      <div class="card mb-3" :class="{ selected: selectedModelForAgentCreation.type === model.type }">
        <div class="coming-soon" v-if="model.isDisabled">Coming Soon</div>
        <img
            class="model w-100 p-5 me-3 mb-0"
            :class="{ disabled: model.isDisabled }"
            :src="model.src"
            :alt="model.alt"
        />
        <div class="d-flex justify-content-center">
          <h5>{{ model.type }}</h5>
        </div>
        <div class="d-flex justify-content-center">
          <h5> Multiplier: {{ model.multiplier }}x</h5>
        </div>
        <div v-if="model.providers.length > 0" class="d-flex justify-content-around p-2">
          <select v-model="model.selectedProvider" class="form-select-lg mb-3 w-100" :disabled="model.isDisabled">
            <option v-for="provider in model.providers" :key="provider" :value="provider">{{ provider }}</option>
          </select>
        </div>
        <div class="d-flex justify-content-around p-0">
          <button
              class="btn btn-primary w-100 m-0"
              @click="selectModel(model.type, model.selectedProvider)"
              :disabled="model.isDisabled">
            Choose Model
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="container p-0 text-center d-none">
    <div class="row d-flex justify-content-center align-items-center mb-3 alert alert-warning p-0">
      <div class="col-12 col-md-6">
        <div class="alert-icon">
          <i class="fas fa-exclamation-triangle"></i>
        </div>
        <img
            class="w-75 me-3 mb-0"
            src="@/assets/img/illustrations/gym-dynamic-gradient.png"
            alt="logo"
        />
      </div>
      <div class="col-12 col-md-6">
        <h1 style="color: white">Choose a model!</h1>
        <h2 style="color: white">This feature will be out soon!</h2>
        <p>
          You will soon be able to choose from a variety of models to chat and train with. Stay tuned!
        </p>
      </div>
    </div>
  </div>
</template>



<script>
import Gemini from '@/assets/img/logos/Gemini.png';
import Llama from '@/assets/img/logos/Llama.png';
import OpenAI from '@/assets/img/logos/OpenAI.png';
import Mistral from '@/assets/img/logos/Mistral.svg';
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      models: [
        {
          src: OpenAI,
          alt: "OpenAI logo",
          type: "GPT-4o",
          multiplier: 6.0,
          providers: ["OpenAI", "Azure"],
          selectedProvider: "OpenAI",
          isDisabled: false
        },{
          src: OpenAI,
          alt: "OpenAI logo",
          type: "GPT-4o-mini",
          multiplier: 3.0,
          providers: ["OpenAI", "Azure"],
          selectedProvider: "OpenAI",
          isDisabled: false
        },{
          src: OpenAI,
          alt: "OpenAI logo",
          type: "GPT-3.5-turbo-0125",
          multiplier: 4,
          providers: ["OpenAI"],
          selectedProvider: "OpenAI",
          isDisabled: false
        },{
          src: OpenAI,
          alt: "OpenAI logo",
          type: "GPT-3.5-turbo-0613",
          multiplier: 4.1,
          providers: ["OpenAI"],
          selectedProvider: "OpenAI",
          isDisabled: false
        },{
          src: OpenAI,
          alt: "OpenAI logo",
          type: "GPT-3.5-turbo-1106",
          multiplier: 4.1,
          providers: ["OpenAI"],
          selectedProvider: "OpenAI",
          isDisabled: false
        },
        {
          src: Llama,
          alt: "Llama logo",
          type: "Llama-3.1-8b-instruct",
          multiplier: 1.2,
          providers: ["TuneAI"],
          selectedProvider: "TuneAI",
          isDisabled: true
        },
        {
          src: OpenAI,
          alt: "OpenAI logo",
          type: "GPT-4",
          multiplier: 8,
          providers: ["Azure"],
          selectedProvider: "Azure",
          isDisabled: false
        },
        {
          src: Llama,
          alt: "Llama logo",
          type: "Llama-3.1-70b-instruct",
          multiplier: 1.8,

          providers: ["TuneAI"],
          selectedProvider: "TuneAI",
          isDisabled: true
        },
        {
          src: Llama,
          alt: "Llama logo",
          type: "Llama-3.1-405b-instruct",
          multiplier: 2.5,
          providers: ["TuneAI"],
          selectedProvider: "TuneAI",
          isDisabled: true
        },
        {
          src: Mistral,
          alt: "Mistral logo",
          type: "Mistral-Large2",
          multiplier: 3.5,
          providers: ["TuneAI"],
          selectedProvider: "TuneAI",
          isDisabled: true
        },
        {
          src: Gemini,
          alt: "Gemini logo",
          type: "Gemini",
          multiplier: 2.5,
          providers: ["Google", "Google"],
          selectedProvider: "Google",
          isDisabled: true
        },
      ],
    };
  },
  props: {
    editMode: Boolean,
  },
  computed: {
    ...mapState(['selectedModelForAgentCreation', 'selectedProviderForAgentCreation', 'selectedExpert']),
  },
  methods: {
    ...mapActions(['setModelForAgentCreation', 'setProviderForAgentCreation']),
    selectModel(type, provider) {
      const model = this.models.find(model => model.type === type);
      if (!model.isDisabled) {
        console.log('Selected Model:', model);
        this.setModelForAgentCreation(model);
        console.log('Selected Provider:', provider)
        this.setProviderForAgentCreation(provider);
      }
    },
  },
  created() {
    // Set the default model based on type
    const defaultModel = this.models.find(model => model.type === this.selectedModelForAgentCreation.type);
    if (defaultModel) {
      this.selectModel(defaultModel.type, defaultModel.selectedProvider);
    }
  }
};

</script>

<style scoped>
.row .model-container {
  position: relative;
}

.row img.model {
  max-width: 100%;
  height: auto;
  cursor: pointer;
}

.row img.model.disabled {
  filter: grayscale(100%);
  opacity: 0.5;
  cursor: not-allowed;
}

.coming-soon {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
}

.card.selected {
  border: 2px solid #007bff;
}
</style>
