<template>
  <div>
    <ul class="list-group">
      <li v-for="(key, index) in apiKeys" :key="index" class="list-group-item d-flex justify-content-between align-items-center">
        {{ key }}
        <button class="btn btn-danger btn-sm mb-0" @click="removeKey(index)">Remove</button>
      </li>
    </ul>
    <div class="mt-3">
      <button class="btn btn-primary" @click="generateKey">Add Key</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ApiKeys",
  props: {
    apiKeys: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      newApiKey: ""
    };
  },
  methods: {
    generateKey() {
      const length = Math.floor(Math.random() * (48 - 36 + 1)) + 36;
      const randomString = Array(length).fill(0).map(() => Math.random().toString(36).charAt(2)).join('');
      const newApiKey = `bbot_${randomString}`;
      this.$emit("add-key", newApiKey);
    },
    removeKey(index) {
      this.$emit("remove-key", index);
    }
  }
};
</script>

<style scoped>
/* Add any scoped styles here */
</style>
