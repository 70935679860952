<template>
  <VueFinalModal v-model="isVisible"
                 class="flex justify-center items-center confirm-modal overflow-scroll"
                 content-class="flex flex-col max-w-xl mx-auto p-4 dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-2 confirm-modal-content"
                 overlay-transition="vfm-fade"
                 :hide-overlay="true"
                 content-transition="vfm-fade">
    <template #default>

      <div class="">
        <div class="card-header bg-gradient-primary d-flex justify-content-between shadow-primary card-header-top mb-3">
          <h5 class="modal-title text-white">{{ isEditMode ? 'Edit Author' : 'Create Author' }}</h5>
          <!-- Steps Indicator for Editing Author -->

          <button type="button" class="close-btn btn text-white" @click="closeModal">
            <i class="material-icons-round opacity-10 fs-5">close</i>
          </button>
        </div>
        <div class="modal-scrollable-content">
          <transition name="slide-fade" mode="out-in">
            <!-- Step Content Wrapper -->
            <div :key="currentStep">
              <div class="steps-indicator d-flex justify-content-center">
                <button v-for="step in totalSteps" :key="step" class="btn" :class="{ 'btn-success shadow-success': currentStep === step, 'btn-secondary': currentStep !== step }" @click="navigateToStep(step)">
                  <span>{{ step }}</span>
                  <i class="material-icons-round opacity-10 fs-5 icon-steps">keyboard_arrow_right</i>
                </button>
              </div>
              <!-- Step 1: Basic Information -->
              <div v-if="currentStep === 1">
                <div class="p-4">
                  <label>Name</label>
                  <input v-model="authorData.name" type="text" class="form-control mb-3">
                  <label>Mobile</label>
                  <input v-model="authorData.phone_number" type="text" class="form-control mb-3">
                  <label>Email</label>
                  <input v-model="authorData.email" type="email" class="form-control">
                  <div class="mb-3">
                    <label for="profileInformation" class="form-label">Profile Information</label>
                    <textarea id="profileInformation" class="form-control" v-model="authorData.profile_information"></textarea>
                  </div>
                </div>
              </div>



              <!-- Additional steps as needed... -->

              <!-- Final Step: Review & Save -->
              <div v-if="currentStep === totalSteps">
                <div class="p-4">
                  <h5>Review Information</h5>
                  <p><strong>Name:</strong> {{ authorData.name }}</p>
                  <p><strong>Mobile:</strong> {{ authorData.phone_number }}</p>
                  <p><strong>Email:</strong> {{ authorData.email }}</p>
                  <p><strong>Profile Information:</strong> {{ authorData.profile_information }}</p>
                  <!-- Add other fields as necessary -->
                  <button class="btn bg-gradient-success mt-3" @click="saveAuthor">
                    Save
                  </button>
                </div>
              </div>

            </div>
          </transition>
        </div>

        <!-- Navigation Buttons -->
        <div class="navigation-buttons-container">
          <div class="navigation-buttons">
            <button class="btn btn-secondary" @click="previousStep" v-if="currentStep > 1">Previous</button>
            <button class="btn btn-secondary" @click="nextStep" v-if="currentStep < totalSteps">Next</button>
          </div>
        </div>
      </div>
    </template>
  </VueFinalModal>
</template>

<script>
import {VueFinalModal} from "vue-final-modal";
import {mapState} from "vuex";

export default {
  components: {VueFinalModal},
  data() {
    return {
      isVisible: false,
      currentStep: 1,
      totalSteps: 2,
      isEditMode: false,
      authorData: {
        name: '',
        phone_number: '',
        email: '',
        profile_information: '',
      },
    };
  },
  watch: {
    isVisible(newVal) {
      console.log("hallo")
      if (newVal) {
        console.log("hallo2")

        this.currentStep = 1;
        // Populate the form with the author's details from the store
        const authorAttributes = this.author.attributes;
        console.log(authorAttributes);
        this.authorData.name = authorAttributes.name;
        this.authorData.email = authorAttributes.email;
        this.authorData.phone_number = authorAttributes.phone_number;
        this.authorData.profile_information = authorAttributes.profile_information || ''; // Populate profile_information
      }
    }
  },
  computed: {
    ...mapState(["author"]),
    authorAttributes() {
      console.log("Author from Vuex:", this.author);
      return this.author.attributes || {};
    },
  },
  methods: {
    navigateToStep(step) {
      this.currentStep = step;
    },
    previousStep() {
      if (this.currentStep > 1) this.currentStep--;
    },
    nextStep() {
      if (this.currentStep < this.totalSteps) this.currentStep++;
    },
    openModal() {
      this.isVisible = true;
      console.log("Open Modal - isVisible:", this.isVisible);
    },
    closeModal() {
      this.isVisible = false;
      console.log("Close Modal - isVisible:", this.isVisible);
    },
    async saveAuthor() {
      // Retrieve the author ID from the Vuex store
      const authorId = this.$store.state.author.id;

      // Update the authorData object with the email from the store if it's not changed
      // This is just an example, ensure you handle this based on your form's needs
      if (!this.authorData.email) {
        this.authorData.email = this.$store.state.author.attributes.email;
      }

      // Dispatch the Vuex action to save/update the author's information
      // Note: Assuming 'editAuthorOfUser' can handle both creation and update based on the presence of an ID
      this.$store.dispatch('editAuthorOfUser', { authorId, authorData: this.authorData })
          .then(response => {
            if (response.success) {
              this.isVisible = false; // Close modal on success
              // Optionally, trigger a notification or a message to the user
              console.log('Author updated successfully');
            } else {
              // Handle failure - display error message or notification
              console.error('Failed to update author:', response.error);
            }
          })
          .catch(error => {
            console.error('Error updating author:', error);
          });
    }
  },
};
</script>
<style scoped>


  .card-body, .card-footer {
    padding: 1rem;
  }

  .btn-close {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: white;
    cursor: pointer;
  }

  .mr-button{
    margin-right: 1rem;
  }

  .business-card {
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    padding: 20px;
    max-width: 70%;
    margin: auto;
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem 1rem;
    border-radius: .75rem .75rem .75rem .75rem !important;
  }

  .profile-picture {
    margin-right: 15px;
    border-radius: 50%;
  }

  .card-title {
    margin: 0;
    color: #333333;
    font-weight: bold;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-group label {
    font-size: 14px;
    color: #666666;
    margin-bottom: 5px;
  }

  .form-control {
    font-size: 14px;
    color: #333333;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    padding: 10px;
  }

  .form-control::placeholder {
    color: #999999;
  }

  .navigation-buttons-container {
    display: flex;
    justify-content: center; /* Center the buttons container horizontally */
    position: fixed; /* Positioning relative to the viewport */
    left: 0;
    right: 0;
    bottom: 20px; /* Adjust the distance from the bottom */
    pointer-events: none; /* Ensures that this container doesn't block interactions */
  }

  .navigation-buttons {
    pointer-events: auto; /* Re-enable pointer events for buttons */
  }

  /* Slide-fade transition for entering and leaving */
  .slide-fade-enter-active, .slide-fade-leave-active {
    transition: transform 0.5s ease, opacity 0.5s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to /* leave-to for <= Vue 2.1.8 */ {
    transform: translateX(-100%);
    opacity: 0;
  }

  /* Backward transition */
  .slide-fade-reverse-enter-active, .slide-fade-reverse-leave-active {
    transition: transform 0.5s ease, opacity 0.5s ease;
  }
  .slide-fade-reverse-enter, .slide-fade-reverse-leave-to /* leave-to for Vue 2.1.8 and below */ {
    transform: translateX(100%);
    opacity: 0;
  }
  .close-btn {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 1.7rem;
    margin-right: 1rem;
    color: white;
  }

</style>
