<template>
  <div class="card-header bg-gradient-primary d-flex justify-content-between shadow-primary card-header-top">
    <h5 class="modal-title text-white">Select a Model</h5>
  </div>
  <div class="container">
    <div class="row justify-content-center mt-3 card">
      <div class="network-graph-container mt-4">
        <v-network-graph
            :nodes="nodes"
            :edges="edges"
            :layouts="layouts"
            :paths="paths"
            :configs="configs"
            :event-handlers="eventHandlers"
            :selected-nodes="selectedNodes"
        />
      </div>
    </div>
    <div v-if="selectedModelInfo" class="model-info row card">
      <h3>Model Information</h3>
      <p><strong>Name:</strong> {{ selectedModelInfo.name }}</p>
      <h5>Training Session</h5>

      <p><strong>Name:</strong> {{ selectedModelInfo.trainingSessionName }}</p>
      <p><strong>Description:</strong> {{ selectedModelInfo.description }}</p>
      <!-- Add more fields as necessary -->
      <button class="btn btn-primary" @click="confirmSelection">Select Model</button>
    </div>
  </div>
</template>


<script>
import * as vNG from 'v-network-graph';
import {mapState, mapMutations, mapActions} from 'vuex';

export default {
  components: {
    vNetworkGraph: vNG.VNetworkGraph,
  },
  props: {
    modelValue: Boolean,
  },
  data() {
    return {
      searchQuery: '',
      selectedNodes: this.selectedModel ? [`node${this.selectedModel.id}`] : [],
      selectedModelInfo: null,
      configs: vNG.defineConfigs({
        node: {
          selectable: true,
          normal: {
            type: "circle",
            radius: 20,
          },
          hover: {
            color: "#88bbff",
          },
          label: {
            visible: true,
            fontSize: 16,
          },
        },
        edge: {
          gap: 40,
          normal: {
            color: "#6699cc",
          },
          selected: {
            color: "#ff0000", // red color for selected edges
          },
          type: "curve",
        },
        path: {
          visible: false,
          curveInNode: true,
          normal: {
            width: 10,
          },
        },
      }),
      eventHandlers: {
        "node:click": (event) => {
          console.log('Node click event:', event); // Debugging output
          this.displayModelInfo(event.node);
        },
      }
    };
  },
  mounted() {
    this.updateSelectedNode(this.selectedModel);
  },
  watch: {
    selectedModel(newModel) {
      console.log("Selected Model Watcher - New Model:", newModel);
      this.updateSelectedNode(newModel);
    },
  },
  computed: {
    ...mapState(['selectedExpert', 'models', 'selectedModel']),
    nodes() {
      const nodes = {};
      let currentX = 0;  // Horizontal position starter for new strands
      const yIncrement = 100;  // Vertical spacing between models
      const xSpacing = 200;    // Horizontal spacing for split models

      // Get model IDs from selectedExpert
      const expertModelIds = this.selectedExpert.attributes.expert_llm_models.data.map(model => model.id);
      // Filter models based on selectedExpert
      const expertModels = this.models.filter(model => expertModelIds.includes(model.id));

      // Sort models to ensure proper chaining from base to advanced
      const sortedModels = expertModels.sort((a, b) => {
        const aPrev = a.attributes.previous_models.data.length;
        const bPrev = b.attributes.previous_models.data.length;
        return aPrev - bPrev;
      });

      // Create nodes, positioning start nodes in a new line and descendants vertically aligned above their predecessors
      sortedModels.forEach((model) => {
        const nodeId = `node${model.id}`;
        const isSelected = (this.selectedModel && this.selectedModel.id === model.id);

        if (model.attributes.previous_models.data.length === 0) {
          // This model starts a new line
          nodes[nodeId] = {
            name: model.attributes.name,
            x: currentX,
            y: 0,
            selected: isSelected
          };

          currentX += xSpacing;  // Increment horizontal space for the next new line
        } else {
          // This model is a descendant and should be placed above its predecessor
          const prevModelId = model.attributes.previous_models.data[0].id;
          const prevNode = nodes[`node${prevModelId}`];
          let xPos = prevNode.x;
          let yPos = prevNode.y + yIncrement;

          // Check if there is already a node at this Y position to avoid overlap
          Object.values(nodes).forEach(existingNode => {
            if (existingNode.y === yPos && existingNode.x === xPos) {
              xPos += xSpacing; // Move to the right if overlap detected
            }
          });

          nodes[nodeId] = {
            name: model.attributes.name,
            x: xPos,
            y: yPos,
            selected: isSelected,
            zIndex: 2,
          };
        }
      });

      return nodes;
    },
    edges() {
      const edges = {};

      this.models.forEach(model => {
        if (model.attributes.previous_models.data.length > 0) {
          const sourceNodeId = `node${model.attributes.previous_models.data[0].id}`;
          const targetNodeId = `node${model.id}`;
          const edgeId = `edge${model.id}`;
          edges[edgeId] = {
            source: sourceNodeId,
            target: targetNodeId,
            color: model.id === 'specialEdgeModelId' ? '#ff0000' : '#6699cc', // Change 'specialEdgeModelId' to the correct condition
          };
        }
      });

      return edges;
    },
    layouts() {
      const layouts = { nodes: {} };
      Object.entries(this.nodes).forEach(([nodeId, node]) => {
        layouts.nodes[nodeId] = { x: node.x, y: node.y };
      });
      return layouts;
    },
    isModalOpen: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    ...mapMutations(['setSelectedModel']),
    ...mapActions(['fetchModels']),
    displayModelInfo(node) {
      console.log('Selected Model before displayModelInfo:', this.selectedModel);

      if (!node) {
        console.log("Node is undefined.");
        return;
      }

      const nodeId = node;
      console.log('Generated nodeId:', nodeId);

      const modelId = parseInt(nodeId.replace('node', ''), 10);
      const model = this.models.find(model => model.id === modelId);
      console.log('Model found in displayModelInfo:', model);
      this.setSelectedModel(model);
      if (model) {
        this.selectedModelInfo = {
          name: model.attributes?.name ?? 'First Model',
          trainingSessionName: model.attributes?.training_sessions?.data?.[0]?.attributes?.name ?? 'A new Journey Starts Here',
          description: model.attributes?.training_sessions?.data?.[0]?.attributes?.description ?? 'Let us start the journey together! 🚀',
        };
        //this.$emit('model-info-displayed');
      } else {
        console.log("Model not found for nodeId:", nodeId);
      }
      console.log('Selected Model after displayModelInfo:', this.selectedModel);
    },

    updateSelectedNode(newModel) {
      if (newModel && newModel.id) {
        const newSelectedNodeId = `node${newModel.id}`;
        this.selectedNodes = [newSelectedNodeId];
        this.selectedModelInfo = {
          name: newModel.attributes.name,
          description: newModel.attributes.description,
          // Add more fields as necessary
        };
      } else {
        console.log("No valid selected model. Keeping current selection.");
      }
    },

    confirmSelection() {
      console.log('Selected model HERE:', this.selectedModel);
      this.$emit('model-selected');
    },
  },
};
</script>


<style scoped>
.search-box {
  margin-bottom: 1rem;
}

.search-input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
}

.conversations-list {
  list-style: none;
  padding: 0;
}

.conversation-btn {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: left;
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1.7rem;
  margin-right: 1rem;
  color: white;
}

.confirm-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000 !important;
  border-bottom-left-radius: .75rem;
  border-bottom-right-radius: .75rem !important;
}

.confirm-modal >>> .confirm-modal-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: #fff;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.confirm-modal-content h1 {
  font-size: 1.375rem;
}

.dark .confirm-modal-content {
  background: #000;
}

.active .card {
  border: 3px solid green;
}

.card-header {
  border-radius: .75rem .75rem .75rem .75rem !important;
}

.network-graph-container {
  width: 100%;
  height: 400px; /* Adjust as needed */
}

.model-info {
  margin-top: 20px;
  padding: 15px;
}
</style>
