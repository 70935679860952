<template>
  <VueFinalModal
      v-model="isModalOpen"
      class="flex justify-center items-center confirm-modal overflow-scroll"
      content-class="flex flex-col max-w-xl p-4 dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-2 confirm-modal-content"
      overlay-transition="vfm-fade"
      content-transition="vfm-fade"
  >
    <template #header>
      <h3>Select a Conversation</h3>
    </template>

    <template #default>
      <div class="card-header bg-gradient-primary d-flex justify-content-between shadow-primary card-header-top">
        <h5 class="modal-title text-white">Select a Conversation</h5>
      </div>
      <div class="container">

        <div class="row justify-content-center">
          <div class="w-100">
            <div class="search-box">
              <input type="text" v-model="searchQuery" placeholder="Search conversations..." class="search-input">
            </div>
            <ul class="conversations-list">
              <li v-for="(conversation, index) in filteredConversations" :key="index">
                <button @click="selectConversation(conversation)" class="btn bg-gradient-primary w-100">
                  {{ conversation.attributes.name }}
                </button>
              </li>
            </ul>
            <button @click="closeModal" class="close-btn btn">
              <i class="material-icons-round opacity-10 fs-5">close</i>
            </button>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <button @click="closeModal" class="close-btn">Close</button>
    </template>
  </VueFinalModal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'
import { mapState, mapMutations } from 'vuex'

export default {
  components: {
    VueFinalModal,
  },
  props: {
    modelValue: Boolean,
  },
  data() {
    return {
      searchQuery: '',
    };
  },
  computed: {
    ...mapState(['selectedExpert']),
    filteredConversations() {
      if (this.selectedExpert && this.selectedExpert.attributes.conversations.data.length > 0) {
        return this.selectedExpert.attributes.conversations.data.filter(conversation =>
            conversation.attributes.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }
      return [];
    },
    isModalOpen: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    ...mapMutations(['setSelectedConversation']),
    selectConversation(conversation) {
      this.setSelectedConversation(conversation);
      this.isModalOpen = false; // Close the modal
    },
    closeModal() {
      this.isModalOpen = false;
    },
  },
};
</script>

<style scoped>
.search-box {
  margin-bottom: 1rem;
}

.search-input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
}

.conversations-list {
  list-style: none;
  padding: 0;
}

.conversation-btn {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: left;
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1.7rem;
  margin-right: 1rem;
  color: white;
}

.confirm-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000 !important;
  border-bottom-left-radius: .75rem;
  border-bottom-right-radius: .75rem;
}


.confirm-modal >>> .confirm-modal-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: #fff;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.confirm-modal-content > * + *{
  margin: 0.5rem 0;
}
.confirm-modal-content h1 {
  font-size: 1.375rem;
}

.dark .confirm-modal-content {
  background: #000;
}
.card-header{
  border-radius: .75rem .75rem .75rem .75rem !important;
}

</style>
