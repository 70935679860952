<template>
  <VueFinalModal
      v-model="isModalOpen"
      class="flex justify-center items-center confirm-modal overflow-scroll"
      content-class="flex flex-col max-w-xl mx-auto p-4 dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-2 confirm-modal-content"
      overlay-transition="vfm-fade"
      content-transition="vfm-fade"
  >
    <template #header>
      <h3>Connectors</h3>
      <button @click="closeModal" class="close-btn btn">
        <i class="material-icons-round opacity-10 fs-5">close</i>
      </button>
    </template>

    <template #default>
      <div class="card-header bg-gradient-primary d-flex justify-content-between shadow-primary card-header-top">
        <h5 class="modal-title text-white">Connectors & Tools</h5>
      </div>
      <div class="container">
        <div class="row justify-content-center">

          <div class="connector-switch card" v-for="(active, key) in toolActivation" :key="key">
            <input class="w-100" type="checkbox" :id="key" v-model="toolActivation[key]" @change="toggleTool({tool: key, value: toolActivation[key]})">
            <label class="w-100 text-align-center" :for="key">{{ key.replace('_', ' ').toUpperCase() }}</label>
          </div>
        </div>
      </div>
      <button @click="closeModal" class="close-btn btn">
        <i class="material-icons-round opacity-10 fs-5">close</i>
      </button>
    </template>

    <template #footer>
      <button @click="closeModal" class="close-btn">Close</button>
    </template>
  </VueFinalModal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'
import { mapState, mapMutations } from 'vuex'

export default {
  components: {
    VueFinalModal,
  },
  props: {
    modelValue: Boolean,
  },
  computed: {
    ...mapState(['toolActivation']),
    isModalOpen: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    ...mapMutations(['toggleToolActivation']),
    toggleTool(payload) {
      this.toggleToolActivation(payload);
    },
    closeModal() {
      this.isModalOpen = false;
    }
  },
};
</script>

<style scoped>
.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1.7rem;
  margin-right: 1rem;
  color: white;
}

.text-align-center{
  text-align: center;
}

.connector-switch {
  margin: 1rem;
  padding: 0.5rem;
  background-color: #f8f9fa;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
}

.confirm-modal{
  border-bottom-left-radius: .75rem;
  border-bottom-right-radius: .75rem;
}

.connector-switch input {
  margin-right: 0.5rem;
}

.connector-switch label {
  margin: 0;
}

.card {
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.card:hover {
  box-shadow: 0 12px 24px rgba(0,0,0,0.1);
}
.card-header{
  border-radius: .75rem .75rem .75rem .75rem !important;
}
</style>
